import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { CompanyService } from "../../shared/services/company.service";
import { Company } from "../../shared/company.interface";
import { CommonRespMess } from "../../shared/common-resp-mess.interface";
import { selectCompany, companyFeatureSelector } from "../../reducers/company";

@Injectable({
  providedIn: "root",
})

@Component({
  selector: "app-company-select",
  templateUrl: "./company-select.component.html",
  styleUrls: ["./company-select.component.scss"],
})
export class CompanySelectComponent implements OnInit {
  companies: Company[] = [];
  companySelector!: FormGroup;

  defaultCompany!: number;
  compamyUUID$ = this._store.select(companyFeatureSelector);
  subscribtion1!: Subscription;

  get selectedCompany() {
    return this.compamyUUID$;
  }

  constructor(
    private _companyService: CompanyService,
    private _store: Store,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getCompanies();
    this.companySelector = this._formBuilder.group({
      selectedCompany: [null, []],
    });
  }

  ngOnDestroy(): void {
    this.subscribtion1.unsubscribe();
  }

  getCompanies() {
    this.subscribtion1 = this._companyService.getCompanies().subscribe({
      next: (resp: CommonRespMess) => {
        console.log({ resp });
        this.companies = resp.message;
        this.defaultCompany = resp.message.length > 1 ? "" : resp.message[0];
        this.companySelector
          .get("selectedCompany")!
          .setValue(this.defaultCompany);

        console.log("Default", this.defaultCompany, resp.message[0].id);
      },
      error: (error) => {
        console.log("Ошибка получения списка компаний", error);
      },
      complete: () => {},
    });
  }

  getCompanyContent(company: Company) {
    let uuid = company.uuid || "";
    this._store.dispatch(selectCompany(uuid));
  }
}
