<div
  *ngIf="_errorService.error$ | async as error"
  class="global-error-msg"
  >
    {{ error }}

  <button
    class="global-error-btn"
    (click)="_errorService.clear()"
    >
      &times;
  </button>
</div>
