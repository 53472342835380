<div class="container">
  <div class="row my-3 mt-md-5 mb-md-4">
    <div class="col">
      <h1>{{ rLabel }}</h1>
    </div>
  </div>

  <div *ngIf="authToken !== null" class="my-0 my-md-4">
    <div class="row">
      <div class="col">
        <h2>Мои менеджеры</h2>
      </div>
    </div>

    <div class="row">
      <div class="col col-md-6 col-lg-4" *ngFor="let manager of managerList">
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="loading"
        ></mat-progress-bar>
        <div class="usr-card">
          <div class="usr-pic">
            <img
              class="mat-elevation-z3"
              src="../../assets/img/{{ manager.email.toLowerCase() }}.jpg"
              alt="{{ manager.name }} {{ manager.surname }}"
            />
          </div>
          <div class="usr-info">
            <div class="usr-role">{{ manager.role }}</div>
            <div class="usr-name">{{ manager.name }} {{ manager.surname }}</div>
            <div class="blue-text">
              <a
                href="mailto:{{
                  manager.email
                }}?subject='Обращение через сервис Личный кабинет'"
                >{{ manager.email }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <h2 class="mb-0">Как добраться</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-tab-group (selectedTabChange)="onTabChange($event)" preserveContent>
        <mat-tab label="Офис в Мурманске">
          <div class="row mx-0 my-0">
            <div id="mapMur" class="col-12 col-lg-6 office-info"></div>
            <div class="col-12 col-lg-5 mt-5">
              <ul class="contact-list px-0 px-lg-3">
                <li>
                  <a href="https://yandex.ru/maps/-/CCUFiWTGtC"
                    ><mat-icon>location_on</mat-icon></a
                  >
                  <div>
                    <a href="https://yandex.ru/maps/-/CCUFiWTGtC"
                      >183039, Мурманск, улица Академика Книповича&nbsp;19А,
                      первый этаж</a
                    >
                  </div>
                </li>
                <li class="contact-tel">
                  <a href="tel:88152692700"><mat-icon>local_phone</mat-icon></a>
                  <a href="tel:88152692700">8 (815 2) 69-27-00</a>
                </li>
                <li>
                  <mat-icon class="me-1">access_time</mat-icon>
                  <div>
                    Режим работы:
                    <br />
                    <p>
                      Понедельник — Пятница:
                      <span class="work-time">c 9:00 до 18:00</span><br />
                    </p>
                    <p>
                      <span>Выходные дни: суббота,&nbsp;воскресенье</span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Офис в Санкт-Петербурге">
          <div class="row mx-0 my-0">
            <div id="mapSpb" class="col-12 col-lg-6 office-info"></div>
            <div class="col-12 col-lg-5 mt-5">
              <ul class="contact-list px-0 px-lg-3">
                <li>
                  <a href="https://yandex.ru/maps/-/CCUFiWTGtC"
                    ><mat-icon>location_on</mat-icon></a
                  >
                  <a href="https://yandex.ru/maps/-/CCUFiWTGtC"
                    >197341, Санкт-Петербург, Фермское шоссе&nbsp;12, лит&nbsp;К
                    оф.&nbsp;64-Н</a
                  >
                </li>
                <li class="contact-tel">
                  <a href="tel:88122447133"><mat-icon>local_phone</mat-icon></a>
                  <a href="tel:88122447133"> 8 (812) 244-71-33</a>
                </li>
                <li>
                  <mat-icon class="me-1">access_time</mat-icon>
                  <div>
                    Режим работы:
                    <br />
                    <p>
                      Понедельник — Пятница:
                      <span class="work-time">c 9:00 до 18:00</span><br />
                    </p>
                    <p>
                      <span>Выходные дни: суббота,&nbsp;воскресенье</span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div class="row my-0 my-lg-5">
    <div class="col-6 col-md-3 pb-3">
      <div class="usr-card">
        <div class="usr-info">
          <div class="usr-role">Официальный сайт:</div>
          <div class="blue-text">
            <a href="https://it-murman.ru/" target="_blank">it-murman.ru</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 col-md-3 pb-3">
      <div class="usr-card">
        <div class="usr-info">
          <div class="usr-role">Электронная почта общая:</div>
          <div class="blue-text">
            <a
              href="mailto:info@it-murman.ru?subject='Обращение через сервис Личный кабинет'"
              >info@it-murman.ru</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 col-md-3 pb-3">
      <div class="usr-card">
        <div class="usr-info">
          <div class="usr-role">Коммерческий отдел:</div>
          <div class="blue-text">
            <a href="tel:88152787739">8 (815 2) 78-77-39</a><br />
            <a href="tel:88152692703">8 (815 2) 69-27-03</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 col-md-3 pb-3">
      <div class="usr-card">
        <div class="usr-info">
          <div class="usr-role">
            Линия консультаций по программным продуктам:
          </div>
          <div class="blue-text">
            <a href="tel:88152692701">8 (815 2) 69-27-01</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
