<div class="container">
  <div class="row d-flex">
    <div class="col-md-6 col-sm-12 order-3 order-md-1">
      <h1>Личный кабинет клиента</h1>
      <div class="text-larger">
        <p>Удобный сервис для клиентов Центра консалтинговых проектов.</p>
        <section>
          Подключите личный кабинет и Вы сможете:
          <ul class="items-list" style="list-style: circle">
            <li>
              Смотреть информацию о&nbsp;договорах и&nbsp;взаиморасчётах
              в&nbsp;удобном формате
            </li>
            <li>
              Печатать акты и&nbsp;скачивать отчеты за&nbsp;интересующий период
            </li>
            <!-- <li>
              Получать информацию об&nbsp;акциях и&nbsp;мероприятиях Клуба
              клиентов Центра консалтинговых проектов
            </li> -->
            <li>
              Напрямую обращаться к&nbsp;вашему клиент-менеджеру и&nbsp;многое
              другое
            </li>
          </ul>
        </section>
      </div>
    </div>

    <div class="col-12 col-md-5 offset-md-1 order-1 order-md-2">
      <h1>Вход в личный кабинет</h1>
      <mat-card>
        <div class="row m-lg-3">
          <form method="post" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div>
              <mat-form-field appearance="legacy">
                <mat-label>Электропочта:</mat-label>
                <input
                  matInput
                  type="email"
                  id="email"
                  formControlName="email"
                  placeholder="example@it-murman.ru"
                />
              </mat-form-field>
              <span
                class="text-warn"
                *ngIf="f['email']?.touched && f['email']?.invalid"
                >Требуется действительный адрес электронной почты</span
              >
            </div>

            <div class="mt-3">
              <mat-form-field appearance="legacy">
                <mat-label>Пароль:</mat-label>
                <input
                  matInput
                  type="password"
                  id="password"
                  formControlName="password"
                  autocomplete="off"
                />
              </mat-form-field>
              <span
                class="text-warn"
                *ngIf="f['password']?.touched && f['password']?.invalid"
                >Поле "пароль" не может быть пустым</span
              >
            </div>

            <span class="text-warn" *ngIf="signInErr.value">{{
              signInErr.message
            }}</span>

            <div class="mt-3" style="text-align: right">
              <a class="blue-text text-right" href="auth/restore"
                ><b>Забыли пароль?</b></a
              >
            </div>
            <div>
              <button
                class="my-3"
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="form.invalid"
              >
                Войти
              </button>
            </div>
          </form>
        </div>
        <mat-card-footer>
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="loading"
          ></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>

    <div class="col-12 mt-5 mb-3 mb-md-5 order-2">
      <p class="text-larger">
        Для подключения Личного кабинета отправьте
        <span
          >нам&nbsp;<a href="/auth/request" class="blue-text">заявку</a></span
        >
      </p>
    </div>
  </div>
</div>
