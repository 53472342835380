import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractTimeType'
})
export class ContractTimeTypePipe implements PipeTransform {

  transform(hourly_type: boolean | undefined): string {
    let contractTimeType = '';
    if (hourly_type !== undefined) {
      contractTimeType = hourly_type ? '(почасовой)' : '(тариф)';
    }
    return contractTimeType;
  }

}
