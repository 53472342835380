import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

import { CommonRespMess } from "../../shared/common-resp-mess.interface";
import { companyFeatureSelector } from "../../reducers/company";
import { MapLoaderService } from "../../shared/services/map-loader.service";
import { ManagersService } from "../../shared/services/managers.service";
import { Manager } from "../../shared/manager.interfaces";

@Component({
  selector: "app-lk-contact",
  templateUrl: "./lk-contact.component.html",
  styleUrls: ["./lk-contact.component.scss"],
})
export class LkContactComponent implements OnInit {
  public authToken = localStorage.getItem("access_token");
  public spbMapLoaded = false;
  public loading = false;
  public loading$ = new Subject<boolean>();
  public managerList: Manager[] = [];
  public map!: any;
  public ngUnsubscribe$ = new Subject<void>();
  public rLabel: string = "Контактная информация";

  constructor(
    private _mapLoaderService: MapLoaderService,
    private _managerService: ManagersService,
    private _store: Store
  ) {}

  ngOnInit(): void {
    const ccuuid = this.filterManagers();
    this.loading$.next(true);
  }

  ngAfterViewInit() {
    this._mapLoaderService
      .getMap("mapMur", [68.958889, 33.079722])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((mapMur) => {
        this.map = mapMur;
        this.loading$.next(false);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  onTabChange(event: any) {
    if (event.index == 1 && !this.spbMapLoaded) {
      this.spbMapLoaded = true;
      this._mapLoaderService
        .getMap("mapSpb", [60.013486, 30.308488])
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((mapSpb) => {
          this.map = mapSpb;
          this.loading$.next(false);
        });
    }
  }

  getCurrentManagers(companyUUID: string) {
    if (!this.authToken) return;
    this.loading = true;
    const distinctArray = (array: any) => {
      const mailItems: any = [];
      const managerList: any = [];
      for (let item of array) {
        //if (item.role != "Ответственный от отдела производства") {
        if (item.role != "Руководитель группы сопровождения") {
          if (!mailItems.includes(item.email)) {
            mailItems.push(item.email);
            managerList.push(item);
          }
          else {
            for (let manager of managerList){
              if (manager.email == item.email && manager.role != "Ответственный от отдела производства")
                manager.role = item.role
            }
          }
        }
      }
      this.loading = false;
      return managerList;
    };

    if (companyUUID == "") {
      this._managerService
        .getManagers()
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((managerList: CommonRespMess) => {
          this.managerList = distinctArray(managerList.message);
        });
    } else {
      this._managerService
        .getCompanyManagers(companyUUID)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((managerList: CommonRespMess) => {
          this.managerList = distinctArray(managerList.message);
        });
    }
  }

  getCurrentCompany() {
    this._store
      .select(companyFeatureSelector)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: (resp: string) => {
          //console.log('bb', { resp });
          return resp;
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => {},
      });
  }

  filterManagers() {
    this._store
      .select(companyFeatureSelector)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: (resp: string) => {
          this.getCurrentManagers(resp);
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => {},
      });
  }
}
