<div class="container">

  <div class="section"></div>

  <div class="row">
    <div class="col l12 m6 s4 xs6">
      <h4>{{rLabel}}</h4>
    </div>
  </div>

  <div class="row">
    <div class="col s9">
      <ul class="usr-details">
        <li>
          <div class="usr-name" style="font-size: xx-large; font-weight: bold;">
            {{authUser?.name}} {{authUser?.patronymic}} {{authUser?.surname}}
          </div>
        </li>
        <li>
          <div class="usr-dolj" style="font-weight: bold;" [innerText]="authUser?.position"></div>
        </li>
        <li>
          <div class="usr-email" class="blue-text">{{authUser?.email}}</div>
        </li>
      </ul>
    </div>
  </div>