import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommonRespMess } from "../common-resp-mess.interface";
@Injectable({
  providedIn: 'root'
})
export class ManagersService {

  private _endpoint: string = environment.apiUrl;

  constructor(private _http: HttpClient) { }

  public getManagers(): Observable<CommonRespMess> {
    return this._http.get<CommonRespMess>(`${this._endpoint}/contragent/get_full_managers`);
  }

  public getCompanyManagers(companyUUID: string): Observable<CommonRespMess> {
    return this._http.post<CommonRespMess>(`${this._endpoint}/contragent/get_managers_by_contragent`, { 'contragent_uuid': companyUUID });
  }

}

