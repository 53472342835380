import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import { User, UserActCredentials } from '../user';
// import { UserService } from "./user.service";
import { CommonRespMess } from '../common-resp-mess.interface';
import { RegRequest } from '../reg-request.interface';
import { ErrorService } from './error.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  private _endpoint: string = environment.apiUrl;
  userDetails = {};

  constructor(
    private _http: HttpClient,
    public router: Router,
    // private _userService: UserService,
    private _errorService: ErrorService
  ) {}

  // Авторизация в системе
  signIn(user: User) {
    return this._http.post<CommonRespMess>(
      `${this._endpoint}/auth/login`,
      user
    );
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  checkToken() {
    return this._http.get<any>(`${this._endpoint}/ping`).subscribe((res) => {
      return res === 'PING' ? true : false;
    });
  }

  // login(user: User): Observable<CommonRespMess> {
  //     return this._http.post<CommonRespMess>(`${this._endpoint}/auth/login`, user).pipe(
  //       catchError(this._errorHandler.bind(this))
  //     );
  // }

  doLogout() {
    let removeToken = localStorage.removeItem('access_token');
    if (removeToken == null) {
      this.router.navigate(['']);
    }
  }

  regUser(actUser: UserActCredentials) {
    return this._http
      .post<CommonRespMess>(`${this._endpoint}/auth/registrate`, {
        email: actUser.email,
        password: actUser.password,
      })
      .pipe(catchError(this._errorHandler.bind(this)))
      .subscribe({
        next: (res) => {
          localStorage.setItem('access_token', res.message);
          this.router.navigate(['auth/login']);
        },
      });
  }

  regRequest(reqUser: RegRequest) {
    return this._http
      .post<CommonRespMess>(`${this._endpoint}/auth/reg_request`, reqUser)
      .pipe(catchError(this._errorHandler.bind(this)));
  }

  restorePasswordRequest(actUser: UserActCredentials) {
    return this._http
      .post<CommonRespMess>(`${this._endpoint}/auth/restore_password`, actUser)
      .pipe(catchError(this._errorHandler.bind(this)));
  }

  restorePassword(restoreUser: UserActCredentials) {
    return this._http
      .post<CommonRespMess>(
        `${this._endpoint}/auth/change_password`,
        restoreUser
      )
      .pipe(catchError(this._errorHandler.bind(this)))
      .subscribe(() => {
        //localStorage.setItem('access_token', res.message);
        this.router.navigate(['auth/login']);
      });
  }

  private _errorHandler(error: HttpErrorResponse) {
    this._errorService.handle(error.message);
    return throwError(() => error.message);
  }
}
