<div class="container">
  <div class="row mt-4 mt-md-5 mb-0 mb-md-3">
    <div class="col-4 col-md-3 col-lg-2">
      <h1 class="d-flex align-items-center">
        <span>1С:ИТС</span>&nbsp;&nbsp;
        <button
          mat-icon-button
          class="icon-help"
          (click)="openDialog($event)"
          title="Согласно правилам лицензирования программные продукты Фирмы 1С не могут использоваться без информационно-технологического сопровождения. Информационно-технологическое сопровождение даёт право получать обновления программных продуктов у партнеров Фирмы 1С."
          aria-label="Согласно правилам лицензирования программные продукты Фирмы 1С не могут использоваться без информационно-технологического сопровождения. Информационно-технологическое сопровождение даёт право получать обновления программных продуктов у партнеров Фирмы 1С."
        >
          <mat-icon>help</mat-icon>
        </button>
      </h1>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <table mat-table [dataSource]="itses" class="its-table mat-color-lite">
        <ng-container matColumnDef="nomenclature">
          <th mat-header-cell *matHeaderCellDef>Наименование продукта</th>
          <td mat-cell *matCellDef="let its">
            {{ its.nomenclature }}
          </td>
        </ng-container>
        <ng-container matColumnDef="end_date">
          <th mat-header-cell *matHeaderCellDef>Дата окончания</th>
          <td mat-cell *matCellDef="let its">
            {{ its.end_date }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    </div>
  </div>
</div>
