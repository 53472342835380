import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fileSave from "file-saver";
import { Observable } from "rxjs";

import { companyFeatureSelector } from "../../reducers/company";
import { ContractService } from "../../shared/services/contract.service";
import { Contract } from "../../shared/UserCotract";
import { Product } from "../../shared/product.interface";

declare const M: any;

@Component({
  selector: "app-lk-contract",
  templateUrl: "./lk-contract.component.html",
  styleUrls: ["./lk-contract.component.scss"],
})
export class LkContractComponent implements OnInit {
  companyUUID$: Observable<string> = this._store.select(companyFeatureSelector);
  datePickerStart?: any;
  datePickerEnd?: any;
  modalWin?: any;
  selectedContract?: string;
  thenBlock: string = 'style="color: red;"';
  usrContracts!: Contract[];
  commanyProducts?: Product[];
  currencyFormat = new Intl.NumberFormat("ru-ru", {
    style: "currency",
    currency: "RUB",
  });

  constructor(
    private _contractService: ContractService,
    private _store: Store
  ) {}

  ngOnInit(): void {
    this._store.select(companyFeatureSelector).subscribe({
      next: (resp: string) => {
        this.getCompanyContrList(resp);
      },
      error: (error) => {
        console.log(error);
      },
    });
    this.initModal();
  }

  initModal() {
    let onModalHide = () => {
      this.selectedContract = "";
      this.datePickerStart.el.value = "";
      this.datePickerEnd.el.value = "";
      this.datePickerStart.destroy();
      this.datePickerEnd.destroy();
      //console.log('Закрываемся дропаемся и уходим');
    };
    const elemModal: any = document.querySelector(".modal");
    this.modalWin = M.Modal.init(elemModal, { onCloseStart: onModalHide });
  }

  initControls(): void {
    let pickerProperty = {
      autoClose: true,
      defaultDate: new Date(),
      firstDay: 1,
      format: "dd.mm.yyyy",
      container: "body",
      i18n: {
        cancel: "Отмена",
        months: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
        monthsShort: [
          "Янв",
          "Фев",
          "Мар",
          "Апр",
          "Май",
          "Июн",
          "Июл",
          "Авг",
          "Сен",
          "Окт",
          "Ноя",
          "Дек",
        ],
        weekdays: [
          "Воскресенье",
          "Понедельник",
          "Вторник",
          "Среда",
          "Четверг",
          "Пятница",
          "Суббота",
        ],
        weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        weekdaysAbbrev: ["В", "П", "В", "С", "Ч", "П", "С"],
      },
    };
    const reportStartDatePicker: any =
      document.querySelector("#reportDateBegin");
    const reportEndDatePicker: any = document.querySelector("#reportDateEnd");
    this.datePickerStart = M.Datepicker.init(
      reportStartDatePicker,
      pickerProperty
    );
    this.datePickerEnd = M.Datepicker.init(reportEndDatePicker, pickerProperty);
  }

  showDetails(rowId: string, contrId: number) {
    this.initControls();
    this.selectedContract = rowId;
    let modalContent = document.querySelector(
      ".modal>.modal-content"
    ) as HTMLElement;
    let contrInfo = this.usrContracts.filter((contr) => contr.uuid == rowId);
    let alertStyle = contrInfo[0].balance < 0 ? this.thenBlock : "";

    if (contrInfo[0].hours_limit == 0)
      modalContent.innerHTML = `
      <div class="row">
        <div class="col s12">
        <h4>Детали договора №${contrInfo[0].uuid} от ${
        contrInfo[0].contract_date
      }</h4>
        </div>
      </div>
      <div class="row">
        <div class="col s4">
          <table>
              <tr><td><b>Тип:</b> ${contrInfo[0].type}</td></tr>
              <tr><td><b>Статус:</b> ${contrInfo[0].status}</td></tr>
              <tr><td><b>Стоимость</b> ${this.currencyFormat.format(
                contrInfo[0].summ
              )}</td></tr>
              <tr><td ${alertStyle}><b>Взаиморасчёты:</b> ${this.currencyFormat.format(
        contrInfo[0].balance
      )}</td></tr>
          </table>
        </div>
      </div>`;
    this.modalWin.open();
  }

  getContrList() {
    this._contractService.getContracts().subscribe({
      next: (contrList) => {
        let filteredContrat = contrList.message.filter(function (
          item: Contract
        ) {
          return item.type != "Абонентский договор";
        });
        this.usrContracts = filteredContrat;
        //console.log(contrList)
      },
      error: (e) => console.error(e),
    });
  }

  getCompanyContrList(companyUUID: string) {
    if (!companyUUID) {
      this.getContrList();
    } else {
      this._contractService.getCompanyContracts(companyUUID).subscribe({
        next: (contrList) => {
          let filtContrat = contrList.message.filter(function (item: Contract) {
            return item.type != "Абонентский договор";
          });
          this.usrContracts = filtContrat;
          //console.log(contrList);
        },
        error: (e) => console.error(e),
      });
    }
  }

  getFile(getMethod: string) {
    let preload = document.querySelector(".report-preloader");
    let btnDownloadReport = document.querySelector("#btnDonloadReport");
    let btnViewReport = document.querySelector("#btnViewReport");
    let reportStartDate = Math.floor(
      this.datePickerStart.date.getTime() / 1000
    );
    let reportEndDate = Math.floor(this.datePickerEnd.date.getTime() / 1000);

    preload?.classList.remove("hidden");

    if (reportStartDate > reportEndDate)
      [reportStartDate, reportEndDate] = [reportEndDate, reportStartDate];

    if (getMethod == "download") {
      btnDownloadReport?.classList.add("disabled");
    } else if (getMethod == "view") {
      btnViewReport?.classList.add("disabled");
    }

    this._contractService
      .purchasePdf(this.selectedContract || "", reportStartDate, reportEndDate)
      .subscribe(
        (respMessage: any) => {
          setTimeout(() => {
            this._contractService
              .getPdf(
                this.selectedContract || "",
                reportStartDate,
                reportEndDate
              )
              .subscribe((response: any) => {
                let blob: any = new Blob([response], {
                  type: "application/pdf",
                });
                preload?.classList.add("hidden");
                if (getMethod == "download") {
                  btnDownloadReport?.classList.remove("disabled");
                  fileSave.saveAs(
                    blob,
                    `${this.selectedContract}_${reportStartDate}_${reportEndDate}.pdf`
                  );
                } else if (getMethod == "view") {
                  btnViewReport?.classList.remove("disabled");
                  const url = window.URL.createObjectURL(blob);
                  window.open(url);
                }
              }),
              (error: any) => console.log("Ошибка скачивания файла", error),
              () => console.info("Файл скачан успешно");
          }, 5000);
        },
        (error: any) => {
          console.error(error);
        }
      );
  }
}
