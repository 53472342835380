<h1
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  class="d-inline-flex align-items-start"
>
  <mat-icon color="accent" style="margin-right: 10px; line-height: 32px"
    >help</mat-icon
  >
  <span>Информационно-технологическое сопровождение (ИТС)</span>
</h1>
<mat-dialog-content
  ><p class="paragraph-indent">
    Согласно правилам лицензирования, программные продукты Фирмы «1С» не могут
    использоваться без информационно-технологического сопровождения.
  </p>
  <p class="paragraph-indent">
    Информационно-технологическое сопровождение даёт право получать обновления
    программных продуктов у партнеров Фирмы «1С»
  </p></mat-dialog-content
>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Скрыть</button>
</mat-dialog-actions>
