<mat-form-field [formGroup]="companySelector" appearance="legacy">
  <mat-select
    formControlName="selectedCompany"
    (ngModelChange)="getCompanyContent($event)"
  >
    <mat-option *ngIf="companies.length > 1" value="" selected>
      Все организации
    </mat-option>
    <mat-option *ngFor="let company of companies" [value]="company">
      {{ company.short_company_name }}
    </mat-option>
  </mat-select>
</mat-form-field>
