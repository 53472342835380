import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import { ErrMess } from "../../shared/errMess";

@Component({
  selector: "app-auth-login",
  templateUrl: "./auth-login.component.html",
  styleUrls: ["./auth-login.component.scss"],
})
export class AuthLoginComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  signInErr: ErrMess = { value: false, message: "" };

  constructor(private _auth: AuthService, public router: Router) {}

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  onSubmit() {
    this.loading = true;
    this._auth.signIn(this.form.value).subscribe({
      next: (res) => {
        this.loading = false;
        localStorage.setItem("access_token", res.message);
      },
      error: (e) => {
        //console.log(e.error);
        this.loading = false;
        const errMess = e.error.message;
        this.signInErr.value = true;
        if (errMess == "Incorrect email or password") {
          this.signInErr.message = "Неверный пароль";
        } else if (errMess == "User with this email is not exist") {
          this.signInErr.message =
            "Пользователя с такими учетными данными не существует";
        } else {
          this.signInErr.message = e.error.message;
        }
      },
      complete: () => {
        this.loading = false;
        this.router.navigate(["lk/soprovjdenie"]);
      },
    });
  }

  get f() {
    return this.form.controls;
  }
}
