import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "../../shared/services/auth.service";
import { HttpHeaders } from "@angular/common/http";
import { CommonRespMess } from "../../shared/common-resp-mess.interface";
import { Router } from "@angular/router";
import { ErrMess } from "../../shared/errMess";
import { DialogSoglasieComponent } from "./dialog-soglasie/dialog-soglasie.component";

declare const M: any;

@Component({
  selector: "app-auth-request",
  templateUrl: "./auth-request.component.html",
  styleUrls: ["./auth-request.component.scss"],
})
export class AuthRequestComponent implements OnInit {
  form!: FormGroup;
  headers = new HttpHeaders().set("Content-Type", "application/json");
  loading = false;
  resErr: ErrMess = { value: false, message: "" };

  constructor(
    private _auth: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      company: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required]),
      accessable: new FormControl(null),
      persData: new FormControl(null, [Validators.requiredTrue]),
    });
  }

  onSubmit() {
    this.loading = true;
    // const values = this.form.value;
    this._auth.regRequest(this.form.value).subscribe({
      next: (resp: CommonRespMess) => {
        this.loading = false;
        this.router.navigate(["auth/login"]);
      },
      error: (e) => {
        this.loading = false;
        this.resErr.value = true;
        const errMess = e.error.message;
        if (errMess == "Too many tries for this ip") {
          this.resErr.message =
            "Ошибка! Превышено число попыток отправки формы";
        } else if (errMess == "Mail was already sent") {
          this.resErr.message = `Письмо с запросом на восстановление пароля уже отпралено на адрес ${this.form.value.email}`;
        } else {
          this.resErr.message = errMess;
        }
        console.log(e.error);
      },
    });
  }

  openDialog(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(DialogSoglasieComponent, {
      maxWidth: 800,
    });
    const soglCheckBox = this.form.get("persData");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) soglCheckBox?.setValue(true);
      else soglCheckBox?.setValue(false);
      // console.log(`Dialog result: ${result}`);
    });
  }

  get f() {
    return this.form.controls;
  }

  get persData() {
    return this.form.get("persData");
  }
}

/*

Согласие на обработку персональных данных
Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года свободно, своей волей и в своем интересе выражаю свое безусловное согласие на обработку моих персональных данных ООО «Центр консалтинговых проектов» (ОГРН 1095190002126, ИНН 5190199655), зарегистрированным в соответствии с законодательством РФ по адресу: 183039 Мурманск, улица Академика Книповича 19А, 1й этаж (далее по тексту – Оператор), со следующими условиями:
1. Настоящее Согласие выдано мною на обработку следующих персональных данных:
- фамилия, имя, отчество;
- адрес электронной почты (e-mail);
- номер контактного телефона.
2. Согласие дано Оператору для совершения следующих действий с моими персональными данными с использованием средств автоматизации и/или без использования таких средств: сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, передача третьим лицам для указанных ниже целей, а также осуществление любых иных действий, предусмотренных действующим законодательством РФ как неавтоматизированными, так и автоматизированными способами.
3. Данное согласие дается Оператору для обработки моих персональных данных в следующих целях:
- обработка моего обращения к руководству Оператора и установление со мной связи по теме обращения;
- предоставление мне информации, в том числе рекламной: об акциях, мероприятиях, товарах, услугах, работах Оператора.
4. Третьи лица не обрабатывают персональные данные по поручению Оператора.
5. Трансграничная передача персональных данных Оператором не осуществляется.
6. Настоящее согласие действует до момента его отзыва путем направления соответствующего уведомления на электронный адрес info@it-murman.ru. В случае отзыва мною согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без моего согласия при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 27.07.2006 г.

*/
