import { environment } from "src/environments/environment";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CommonRespMess } from '../common-resp-mess.interface';

@Injectable({
  providedIn: 'root'
})

export class CompanyService {

  endpoint: string = environment.apiUrl;

  constructor(private _httpClient: HttpClient) { }

  getCompanies(): Observable<CommonRespMess> {
    return this._httpClient.get<CommonRespMess>(`${this.endpoint}/contragent/get_contragent_list_by_user`);
  }

}
