<div class="container">
  <div class="row mt-4 mt-md-5 mb-0 mb-md-3">
    <div class="col-4 col-md-3 col-lg-2">
      <h1>Лицензии</h1>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <table
        mat-table
        [dataSource]="licenses"
        class="license-table mat-color-lite"
      >
        <ng-container matColumnDef="product_name">
          <th mat-header-cell *matHeaderCellDef>Наименование продукта</th>
          <td mat-cell *matCellDef="let license">
            {{ license.product_name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="uuid">
          <th mat-header-cell *matHeaderCellDef>Рег. номер</th>
          <td mat-cell *matCellDef="let license">
            {{ license.uuid }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    </div>
  </div>
</div>
