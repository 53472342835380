<div class="container">
  <div class="row d-flex mb-lg-5">
    <div class="col-12 col-md-6 order-2 order-md-1 mb-0 mt-5 mt-md-0">
      <h1>Восстановление пароля</h1>
      <div class="text-larger">
        <ol class="items-list">
          <li>
            Укажите адрес электронной почты на&nbsp;которую зарегистрирован
            сервис
          </li>
          <li>Дождитесь получения уведомления на&nbsp;электронную почту</li>
          <li>Перейдите по ссылке указанной в&nbsp;письме</li>
        </ol>
      </div>
    </div>

    <div class="col-12 col-md-5 offset-md-1 order-1 order-md-2">
      <h1>Укажите адрес электронной почты</h1>
      <mat-card>
        <div class="row m-lg-3">
          <form
            class="col"
            method="post"
            [formGroup]="form"
            (ngSubmit)="onSubmit()"
          >
            <div>
              <mat-form-field appearance="legacy">
                <mat-label>Электропочта:</mat-label>
                <input
                  matInput
                  type="email"
                  id="email"
                  formControlName="email"
                />
              </mat-form-field>
              <span
                class="text-warn"
                *ngIf="f['email']?.touched && f['email']?.invalid"
                >Ошибка! Требуется действительный адрес электронной почты;</span
              >
              <span class="text-warn" *ngIf="resErr.value">{{
                resErr.message
              }}</span>
            </div>

            <div class="my-3">
              <button
                type="submit"
                mat-raised-button
                color="primary"
                [disabled]="form.invalid"
              >
                Восстановить пароль
              </button>
            </div>
          </form>
        </div>
        <mat-card-footer>
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="loading"
          ></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div>
