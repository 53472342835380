<app-global-error></app-global-error>

<mat-drawer-container class="site-container">
  <mat-drawer #sidenav mode="over" closed class="site-sidenav">
    <div class="usr-controls-wrapper d-flex justify-content-center my-4">
      <div class="usr-details">
        <div class="usr-name">
          <a href="/lk/user_profile"
            >{{ userDetails?.name }}&nbsp;{{ userDetails?.surname }}</a
          >
        </div>
        <div class="usr-role">{{ userDetails?.position }}</div>
      </div>
    </div>
    <hr />
    <ul id="slide-out" class="sidenav">
      <li routerLinkActive="active" (click)="sidenav.close()">
        <a routerLink="soprovjdenie">Договоры</a>
      </li>
      <li routerLinkActive="active" (click)="sidenav.close()">
        <a routerLink="licenсe">Лицензии</a>
      </li>
      <li routerLinkActive="active" (click)="sidenav.close()">
        <a routerLink="its">ИТС</a>
      </li>
      <li routerLinkActive="active" (click)="sidenav.close()">
        <a routerLink="contact">Контакты</a>
      </li>
      <li class="drawer-btn">
        <button
          mat-stroked-button
          (click)="getReconciliationAct('download')"
          color="lite"
          class="button-downloadReconciliationAct"
        >
          <mat-icon class="md-24">download</mat-icon> Скачать акт сверки
        </button>
      </li>
      <li class="drawer-btn">
        <button
          mat-stroked-button
          (click)="logout()"
          color="lite"
          class="button-logout"
        >
          <mat-icon class="md-24">logout</mat-icon> Выйти
        </button>
      </li>
    </ul>
  </mat-drawer>
  <mat-drawer-content class="site-content">
    <app-lk-overhead [authUser]="userDetails"></app-lk-overhead>
    <mat-toolbar color="primary" class="mat-elevation-z4">
      <div class="container">
        <div
          class="row d-flex justify-content-sm-start justify-content-md-end overflowy-hidden"
        >
          <div class="col-1 d-block-inline d-md-none">
            <button
              mat-icon-button
              color="lite"
              aria-label="Меню"
              (click)="sidenav.toggle()"
              style="margin-left: -10px"
            >
              <mat-icon>menu</mat-icon>
            </button>
          </div>
          <nav class="col-10 d-none d-md-inline-block">
            <div class="container">
              <ul id="nav-normal" class="col mainnav">
                <li routerLinkActive="active">
                  <a routerLink="soprovjdenie">Договоры</a>
                </li>
                <li routerLinkActive="active">
                  <a routerLink="licenсe">Лицензии</a>
                </li>
                <li routerLinkActive="active"><a routerLink="its">ИТС</a></li>
                <li routerLinkActive="active">
                  <a routerLink="contact">Контакты</a>
                </li>
              </ul>
            </div>
          </nav>
          <button
            mat-stroked-button
            (click)="getReconciliationAct('download')"
            color="lite"
            class="button-downloadReconciliationAct col-3 col-xl-2 d-none d-md-inline-block my-2"
          >
            <mat-icon class="md-24">download</mat-icon> Скачать акт сверки
          </button>
          <button
            mat-stroked-button
            (click)="logout()"
            color="lite"
            class="button-logout col-1 d-none d-md-inline-block my-2"
          >
            <mat-icon class="md-24">logout</mat-icon><span class="d-md-none d-xl-inline">Выйти</span>
          </button>
        </div>
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
    <div class="container my-3 my-md-5">
      <app-banner-social></app-banner-social>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
