<div class="container">
  <div class="row d-flex">
    <div class="col-md-6 col-sm-12 order-2 order-md-1 pt-5">
      <h1>Активация учётной записи</h1>
      <div class="text-larger">
        <ul class="items-list">
          <li>
            <div>
              Закажите услугу подключения личного кабинета через нашу службу
              поддержки:
            </div>
            <div class="contact-tel mt-2">
              <a href="tel:88122447133" style="text-decoration: none"
                ><mat-icon>local_phone</mat-icon> 8 (812) 244-71-33</a
              >
            </div>
          </li>
          <li>Дождитесь получения письма на адрес электронной почты</li>
          <li>Перейдите по ссылке</li>
          <li>Установите новый пароль для Личного кабинета</li>
        </ul>
      </div>
    </div>

    <div class="col-md-5 offset-md-1 col-sm-12 order-1 order-md-2">
      <h1>Добро пожаловать!</h1>
      <mat-card
        ><div class="row m-lg-3">
          <form class="col s12" [formGroup]="formReg" (ngSubmit)="onSubmit()">
            <input type="hidden" id="email" formControlName="email" />
            <div>
              <h2 *ngIf="userRegCredentials">{{ userRegCredentials.email }}</h2>
              <span class="text-warn mt-3" *ngIf="isTokenVerif"
                >Ошибка! Токен авторизации не актуален. Свяжитесь со службой
                поддержки.</span
              >
              <div class="mt-3">
                <mat-form-field>
                  <mat-label>Пароль:</mat-label>
                  <input
                    type="password"
                    matInput
                    id="password"
                    formControlName="password"
                  />
                </mat-form-field>
                <span
                  class="text-warn"
                  *ngIf="f['password']?.touched && f['password']?.invalid"
                  style="max-width: 400px"
                  >Пароль не отвечает требованиям безопасности (должен
                  содержать: более 8 символов, спец символы, буквы разного
                  регистра)</span
                >
              </div>
            </div>
            <div class="mt-3">
              <mat-form-field>
                <mat-label>Подтвердите пароль</mat-label>
                <input
                  type="password"
                  matInput
                  id="passwordConfirm"
                  formControlName="passwordConfirm"
                />
              </mat-form-field>
              <span
                class="text-warn"
                *ngIf="
                  f['passwordConfirm']?.touched &&
                  f['passwordConfirm']?.getError('required') != null
                "
                >Поле подтверждения пароля не может быть пустым</span
              >
              <span
                class="text-warn"
                *ngIf="
                  f['passwordConfirm']?.touched &&
                  f['passwordConfirm']?.getError('MustMatch') != null
                "
                >Введённые пароли не совпадают</span
              >
            </div>
            <div class="my-3">
              <button
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="formReg.invalid"
              >
                {{ actionType }}
              </button>
            </div>
          </form>
        </div>
        <mat-card-footer>
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="loading"
          ></mat-progress-bar>
        </mat-card-footer>
      </mat-card>

      <div></div>
    </div>
  </div>
  <div class="mb-5">&nbsp;</div>
</div>
