import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rubles'
})
export class RublesPipe implements PipeTransform {

  currencyFormat = new Intl.NumberFormat('ru-ru', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0
  });

  transform(summ: number | string | null | undefined): string {
    if (typeof summ != 'number') {
      summ = 0;
    }
    return this.currencyFormat.format(summ);
  }

}
