<div class="container">
  <div class="row d-flex">
    <div
      class="col-12 col-md-8 offset-md-4 col-lg-5 offset-lg-7 order-1 order-md-2 mb-4 mb-md-5"
    >
      <h1>Заявка на подключение Личного кабинета</h1>
      <mat-card class="login-form">
        <div class="row m-lg-3">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div>
              <mat-form-field appearance="legacy">
                <mat-label>Ваше имя</mat-label>
                <input matInput type="text" id="name" formControlName="name" />
              </mat-form-field>
              <span
                class="text-warn"
                *ngIf="f['name']?.touched && f['name']?.invalid"
                >Поле "Ваше имя" не&nbsp;может быть пустым</span
              >
            </div>
            <div class="mt-3">
              <mat-form-field>
                <mat-label>Организация</mat-label>
                <input
                  matInput
                  type="text"
                  id="company"
                  formControlName="company"
                />
              </mat-form-field>
              <span
                class="text-warn"
                color="warn"
                *ngIf="f['company']?.touched && f['company']?.invalid"
                >Название организации не&nbsp;может быть пустым</span
              >
            </div>

            <div class="mt-3">
              <mat-form-field>
                <mat-label>Телефон</mat-label>
                <input
                  matInput
                  type="text"
                  id="phone"
                  formControlName="phone"
                />
              </mat-form-field>
              <span
                class="text-warn"
                *ngIf="f['phone']?.touched && f['phone']?.invalid"
                >Поле "Телефон" не&nbsp;может быть пустым</span
              >
            </div>
            <div class="mt-3">
              <mat-form-field>
                <mat-label>Когда с Вами связаться?</mat-label>
                <input
                  matInput
                  type="text"
                  id="accessable"
                  formControlName="accessable"
                />
              </mat-form-field>
              <span
                class="text-warn"
                *ngIf="f['accessable']?.touched && f['accessable']?.invalid"
                >Поле "Телефон" не&nbsp;может быть пустым</span
              >
            </div>

            <div class="my-3">
              <mat-checkbox
                id="persData"
                formControlName="persData"
                color="primary"
                >Даю
                <a
                  href="#"
                  class="blue-text darken-2"
                  (click)="openDialog($event)"
                  >согласие</a
                >
                на обработку персональных данных</mat-checkbox
              ><br />
              <span
                class="text-warn"
                *ngIf="persData?.touched && persData?.invalid"
                >Для продолжения требуется согласие на&nbsp;обработку
                персональных данных</span
              >
            </div>

            <div *ngIf="resErr.value" class="mt-3">
              <span class="text-warn">{{ resErr.message }}</span>
            </div>

            <button
              class="my-3"
              mat-raised-button
              type="submit"
              color="primary"
              [disabled]="form.invalid"
            >
              Отправить запрос
            </button>
          </form>
        </div>
        <mat-card-footer>
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="loading"
          ></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div>
