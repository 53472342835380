import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { CompanySelectorState, companySelectorReducer } from './company';

export interface State {
  companySelector: CompanySelectorState;
}

export const reducers: ActionReducerMap<State> = {
  companySelector: companySelectorReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];
