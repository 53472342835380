import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { AuthService } from "../../shared/services/auth.service";
import { ErrMess } from "../../shared/errMess";

@Component({
  selector: "app-auth-restore",
  templateUrl: "./auth-restore.component.html",
  styleUrls: ["./auth-restore.component.scss"],
})
export class AuthRestoreComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  resErr: ErrMess = { value: false, message: "" };

  constructor(private _auth: AuthService, public router: Router) {}

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  onSubmit() {
    this.loading = true;
    this._auth.restorePasswordRequest(this.form.value).subscribe(
      {
        next: (res) => {
          this.loading = false;
          console.log(res);
        },
        error: (e) => {
          console.log(e.error);
          this.resErr.value = true;
          this.loading = false;
          const errMess = e.error.message;
          if (errMess == "There is no user with this email") {
            this.resErr.message =
              "Ошибка! Пользователь с такими учетными данными отсутствует";
          } else if (errMess == "Mail was already sent") {
            this.resErr.message = `Письмо с запросом на восстановление пароля уже отпралено на адрес ${this.form.value.email}`;
          } else {
            this.resErr.message = errMess;
          }
        },
        complete: () => {
          this.router.navigate(["auth/login"]);
        },
      }
      // () => {
      // localStorage.setItem('access_token', res.message);
      // this.router.navigate(['lk/soprovjdenie']);
      // }
    );
  }

  get f() {
    return this.form.controls;
  }
}
