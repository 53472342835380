import { AppComponent } from "./app.component";
import { AppEffects } from "./app.effects";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { EffectsModule } from "@ngrx/effects";
import { environment } from "../environments/environment";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MaterialModule } from "./material/material.module";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { ActPeriodCardPipe } from "./pipes/act-period-card.pipe";
import { AuthInterceptor } from "./shared/authconfig.interceptor";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AuthLoginComponent } from "./pages/auth-login/auth-login.component";
import { AuthRegisterComponent } from "./pages/auth-register/auth-register.component";
import { AuthRequestComponent } from "./pages/auth-request/auth-request.component";
import { AuthRestoreComponent } from "./pages/auth-restore/auth-restore.component";
import { CompanySelectComponent } from "./components/company-select/company-select.component";
import { ContractDetailsDialogComponent } from "./pages/lk-soprovojdenie/contract-details-dialog/contract-details-dialog.component";
import { ContractService } from "./shared/services/contract.service";
import { ContractTimeTypePipe } from "./pipes/contract-time-type.pipe";
import { DialogSoglasieComponent } from "./pages/auth-request/dialog-soglasie/dialog-soglasie.component";
import { GlobalErrorComponent } from "./components/global-error/global-error.component";
import { LkItsComponent } from "./pages/lk-its/lk-its.component";
import { LkLicenceComponent } from "./pages/lk-licence/lk-licence.component";
import { LkOverheadComponent } from "./components/lk-overhead/lk-overhead.component";
import { LkSoprovojdenieComponent } from "./pages/lk-soprovojdenie/lk-soprovojdenie.component";
import { LkContactComponent } from "./pages/lk-contact/lk-contact.component";
import { LkContractComponent } from "./pages/lk-contract/lk-contract.component";
import { LkUserprofileComponent } from "./pages/lk-userprofile/lk-userprofile.component";
import { reducers, metaReducers } from "./reducers";
import { RublesPipe } from "./pipes/rubles.pipe";
import { SiteLayoutComponent } from "./layouts/site-layout/site-layout.component";
import { UnixTsToDatePipe } from "./pipes/unix-ts-to-date.pipe";
import { UsrRoleRenamePipe } from "./pipes/usr-role-rename.pipe";
import { BannerSocialComponent } from './components/banner-social/banner-social.component';
import { ItsDialogComponent } from './pages/lk-its/its-dialog/its-dialog.component';

@NgModule({
  declarations: [
    ActPeriodCardPipe,
    AppComponent,
    AuthLayoutComponent,
    AuthLoginComponent,
    AuthRegisterComponent,
    AuthRequestComponent,
    AuthRestoreComponent,
    CompanySelectComponent,
    ContractDetailsDialogComponent,
    ContractTimeTypePipe,
    DialogSoglasieComponent,
    GlobalErrorComponent,
    LkContactComponent,
    LkContractComponent,
    LkItsComponent,
    LkLicenceComponent,
    LkOverheadComponent,
    LkSoprovojdenieComponent,
    LkUserprofileComponent,
    RublesPipe,
    SiteLayoutComponent,
    UnixTsToDatePipe,
    UsrRoleRenamePipe,
    BannerSocialComponent,
    ItsDialogComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    EffectsModule.forRoot([AppEffects]),
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      maxAge: 25,
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
  ],
  providers: [
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
    },
    ContractService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
