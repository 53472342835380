<div class="container">
  <div class="row mt-4 mt-md-5 mb-0 mb-md-3">
    <div class="col-4 col-md-3 col-lg-2">
      <h1>Договоры</h1>
    </div>
    <div class="col-8 col-md-9 col-lg-10">
      <mat-button-toggle-group
        name="contractType"
        aria-label="Фильтр договоров"
        [value]="dispalyedContractsType"
        (change)="filterContracs($event)"
      >
        <mat-button-toggle [value]="contractEnum.abonContracts"
          >Абонентские</mat-button-toggle
        >
        <mat-button-toggle [value]="contractEnum.allContracts"
          >Все</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
  </div>

  <!-- Использование стандартного компонента mat-table Material UI 
    <div class="d-none">
    <div class="col m-0 p-0" style="overflow-x: auto">
      <table mat-table [dataSource]="usrContracts" class="contract-table">
        <ng-container matColumnDef="uuid" sticky>
          <th mat-header-cell *matHeaderCellDef>Договор</th>
          <td mat-cell *matCellDef="let contract">
            {{ contract.uuid }}
          </td>
        </ng-container>
        <ng-container matColumnDef="contract_date">
          <th mat-header-cell *matHeaderCellDef>Дата заключения</th>
          <td mat-cell *matCellDef="let contract">
            {{ contract.contract_date }}
          </td>
        </ng-container>
        <ng-container matColumnDef="end_date">
          <th mat-header-cell *matHeaderCellDef>Дата окончания</th>
          <td mat-cell *matCellDef="let contract">{{ contract.end_date }}</td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Тип</th>
          <td mat-cell *matCellDef="let contract">{{ contract.type }}</td>
        </ng-container>
        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef>Отчётный период</th>
          <td mat-cell *matCellDef="let contract">{{ contract.period }}</td>
        </ng-container>
        <ng-container matColumnDef="summ">
          <th mat-header-cell *matHeaderCellDef>Стоимость за период</th>
          <td mat-cell *matCellDef="let contract">
            {{ contract.summ | rubles }}
          </td>
        </ng-container>
        <ng-container matColumnDef="balance">
          <th mat-header-cell *matHeaderCellDef>Взаиморасчеты</th>
          <td mat-cell *matCellDef="let contract">
            {{ contract.balance | rubles }}
          </td>
        </ng-container>
        <ng-container matColumnDef="id" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-icon mat-icon-button color="accent" title="Детали договора"
              >more_vert</mat-icon
            >
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          [class.highlight-danger]="row.balance < 0"
          *matRowDef="let row; columns: displayedColumns"
          (click)="openDialog($event, row.uuid)"
        ></tr>
      </table>
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    </div>
  </div> -->

  <div class="row mt-3 mt-md-5">
    <div class="col">
      <table
        class="contracts mx-0"
        [ngClass]="{
          active: dispalyedContractsType === contractEnum.abonContracts
        }"
      >
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="loading"
          class="d-md-none"
        ></mat-progress-bar>
        <thead>
          <tr>
            <th>Договор</th>
            <th>Дата заключения</th>
            <th>Дата окончания</th>
            <th *ngIf="dispalyedContractsType === contractEnum.allContracts">
              Тип
            </th>
            <th *ngIf="dispalyedContractsType === contractEnum.abonContracts">
              Отчётный период
            </th>
            <th>Стоимость за период</th>
            <th>Баланс</th>
            <th class="d-none d-md-table-cell"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="pb-3 pb-md-0"
            *ngFor="let contract of usrContracts"
            (click)="openDialog($event, contract.uuid)"
          >
            <td class="d-md-none d-flex align-items-center">
              <mat-icon color="accent" class="me-3">description</mat-icon>
              <b
                >Договор {{ contract.uuid }} от&nbsp;{{
                  contract.contract_date
                }}</b
              >
              <!-- <b>{{ contract.printname }}
              </b> -->
              <mat-icon
                color="accent"
                class="details-icon ms-auto"
                title="Детали договора"
                >more_vert</mat-icon
              >
            </td>
            <td class="d-none d-md-table-cell" aria-label="Дата заключения" *ngIf="contract.printname == ''; else unset">
              {{ contract.uuid }}
            </td>
            <ng-template #unset>  
              <td class="d-none d-md-table-cell" aria-label="Договор">
                {{ contract.printname }}
              </td>  
            </ng-template>
            <!-- <td class="d-none d-md-table-cell" aria-label="Договор">
              {{ contract.uuid }}
            </td> -->
            <td class="d-none d-md-table-cell" aria-label="Дата заключения">
              {{ contract.contract_date }}
            </td>
            <td aria-label="Дата окончания">{{ contract.end_date }}</td>
            <td
              *ngIf="dispalyedContractsType === contractEnum.allContracts"
              aria-label="Тип"
            >
              {{ contract.type }}
            </td>
            <td
              *ngIf="dispalyedContractsType === contractEnum.abonContracts"
              aria-label="Отчетный период"
            >
              {{ contract.period }}
            </td>
            <td aria-label="Стоимость за период">
              {{ contract.summ | rubles }}
            </td>
            <td
              aria-label="Взаиморасчёты"
              [class.highlight-danger]="contract.balance < 0"
            >
              {{ contract.balance | rubles }}
            </td>
            <td class="d-none d-md-table-cell">
              <mat-icon
                color="accent"
                class="details-icon"
                title="Детали договора"
                >more_vert</mat-icon
              >
            </td>
          </tr>
        </tbody>
      </table>
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    </div>
  </div>
</div>
