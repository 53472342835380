import { Component, Inject, OnInit } from "@angular/core";
import { ContractService } from "src/app/shared/services/contract.service";
import * as fileSave from "file-saver";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Subject, takeUntil, throwError } from "rxjs";

import { Act } from "src/app/shared/act.interface";
import { ActService } from "src/app/shared/services/act.service";
import { companyFeatureSelector } from "../../../reducers/company";
import { CommonRespMess } from "src/app/shared/common-resp-mess.interface";
import { Contract } from "src/app/shared/UserCotract";
import { Product } from "../../../shared/product.interface";
import { ReportAbon } from "src/app/shared/report.interface";
import { ErrorService } from "src/app/shared/services/error.service";
import {
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from "@angular/common/http";

@Component({
  selector: "app-contract-details-dialog",
  templateUrl: "./contract-details-dialog.component.html",
  styleUrls: ["./contract-details-dialog.component.scss"],
})
export class ContractDetailsDialogComponent implements OnInit {
  btnGetCustomReport = true;
  contracts: any;
  currentContract?: Contract;
  currentContractActs?: Act[];
  companyProducts?: Product[];
  companyReports?: ReportAbon[] = [];
  customReportDateRange = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  dateFormat = new Intl.DateTimeFormat("ru-ru");
  dateRangeMaxDate = new Date();
  dateRangeMinDate = new Date();
  loading = false;
  ngUnsubscribe$ = new Subject<void>();
  nowDate = new Date();
  curDay = this.nowDate.getDay();
  readyDay = 12;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _actService: ActService,
    private _contractService: ContractService,
    private _store: Store,
    private _errorService: ErrorService
  ) {}

  ngOnInit(): void {
    // console.log(this.data[0].id);
    this.currentContract = this.data[0];
    this.getActs(this.currentContract!.uuid);
    this.getProducts(this.currentContract!.id);
    this.getReports(
      this.currentContract!.contract_date,
      this.currentContract!.period
    );

    if (this.curDay < this.readyDay) {
      this.dateRangeMaxDate.setMonth(this.nowDate.getMonth() - 2);
    } else if (this.curDay >= this.readyDay) {
      this.dateRangeMaxDate.setMonth(this.nowDate.getMonth() - 1);
    }
    this.dateRangeMaxDate = new Date(
      this.dateRangeMaxDate.getUTCFullYear(),
      this.dateRangeMaxDate.getUTCMonth() + 1,
      0
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getDateFromString = (date: string) => {
    let dateArr = date.split(".");
    return new Date(
      parseInt(dateArr[2]),
      parseInt(dateArr[1]) - 1,
      parseInt(dateArr[0])
    );
  };

  getTimeFromString = (date: string) => {
    const dateArr = date.split(".");
    const parsedDate = new Date(
      parseInt(dateArr[2]),
      parseInt(dateArr[1]) - 1,
      parseInt(dateArr[0])
    );
    return parsedDate.getTime() / 1000;
  };

  // getFile(getMethod: string, periodBegin: number = 0, periodEnd: number = 0) {
  //   let preload = document.querySelector(".report-preloader");
  //   preload?.classList.remove("hidden");

  //   let reportStartDate: number;
  //   let reportEndDate: number;
  //   const btnDownloadReport = document.querySelector("#btnDonloadReport");
  //   const btnViewReport = document.querySelector("#btnViewReport");

  //   // Если нет даты начала и конца периода, пытаемся взять даты из полей ввода
  //   if (periodBegin == 0 && periodEnd == 0) {
  //     reportStartDate = Math.floor(this.datePickerStart.date.getTime() / 1000);
  //     reportEndDate = Math.floor(this.datePickerEnd.date.getTime() / 1000);
  //     // Если даты из полей ввода то отключаем соотв методу копку
  //     if (getMethod == "download") {
  //       btnDownloadReport?.classList.add("disabled");
  //     } else if (getMethod == "view") {
  //       btnViewReport?.classList.add("disabled");
  //     }
  //   } else {
  //     reportStartDate = periodBegin;
  //     reportEndDate = periodEnd;
  //   }

  //   if (reportStartDate > reportEndDate)
  //     [reportStartDate, reportEndDate] = [reportEndDate, reportStartDate];

  //   this._contractService
  //     .purchasePdf(this.selectedContract || "", reportStartDate, reportEndDate)
  //     .subscribe(
  //       () => {
  //         setTimeout(() => {
  //           this._contractService
  //             .getPdf(
  //               this.selectedContract || "",
  //               reportStartDate,
  //               reportEndDate
  //             )
  //             .subscribe((fileBinary: any) => {
  //               preload?.classList.add("hidden");
  //               const blob: any = new Blob([fileBinary], {
  //                 type: "application/pdf",
  //               });
  //               if (getMethod == "download") {
  //                 btnDownloadReport?.classList.remove("disabled");
  //                 fileSave.saveAs(
  //                   blob,
  //                   `${this.selectedContract}_${reportStartDate}_${reportEndDate}.pdf`
  //                 );
  //               } else if (getMethod == "view") {
  //                 btnViewReport?.classList.remove("disabled");
  //                 const url = window.URL.createObjectURL(blob);
  //                 window.open(url);
  //               }
  //             }),
  //             (error: any) => console.log("Ошибка скачивания файла", error),
  //             () => console.info("Файл скачан успешно");
  //         }, 4000);
  //       },
  //       (error: any) => {
  //         console.error(error);
  //       }
  //     );
  // }

  getProducts(currentContractId: number) {
    if (!currentContractId) return;
    this._store.select(companyFeatureSelector).subscribe({
      next: (resp: string) => {
        if (resp == "") {
          this._contractService
            .getProducts()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((prodList: CommonRespMess) => {
              this.companyProducts = prodList.message.filter(function (
                item: Product
              ) {
                return item.contract_id == currentContractId;
              });
            });
        } else {
          this._contractService
            .getCompanyProducts(resp)
            .subscribe((prodList: CommonRespMess) => {
              this.companyProducts = prodList.message.filter(function (
                item: Product
              ) {
                return item.contract_id == currentContractId;
              });
            });
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getReports(
    currentContrBeginDate: string,
    curretnContractReportPeriod: string
  ): void {
    this.dateRangeMinDate = this.getDateFromString(currentContrBeginDate);
    const getQuater = (date: Date) => Math.floor(date.getMonth() / 3 + 1);

    this.companyReports = [];
    const beginDate = this.getDateFromString(currentContrBeginDate);

    const periodsArr: any = [];
    const nowDate = new Date();

    if (curretnContractReportPeriod.toLocaleLowerCase() == "квартал") {
      const contrQuaterNum = getQuater(beginDate);
      const contrYear = beginDate.getFullYear();
      const quaters = [
        { qtNum: 1, qtfirstDay: "01.01.", qtLastDay: "31.03." },
        { qtNum: 2, qtfirstDay: "01.04.", qtLastDay: "31.05." },
        { qtNum: 3, qtfirstDay: "01.06.", qtLastDay: "30.09." },
        { qtNum: 4, qtfirstDay: "01.10.", qtLastDay: "31.12." },
      ];

      for (
        let year = beginDate.getFullYear();
        year <= nowDate.getFullYear();
        year++
      ) {
        for (let quater of quaters) {
          if (
            !(
              (quater.qtNum < contrQuaterNum && year == contrYear) ||
              (quater.qtNum >= Math.floor(nowDate.getMonth() / 3 + 1) &&
                year == nowDate.getFullYear())
            )
          ) {
            const firstDateString = quater.qtfirstDay + year;
            const lastDateString = quater.qtLastDay + year;
            periodsArr.push({
              qtNum: quater.qtNum,
              qtfirstDay: this.getTimeFromString(firstDateString),
              qtLastDay: this.getTimeFromString(lastDateString),
              qtName: `${firstDateString} - ${lastDateString}`,
            });
          }
        }
      }
    } else if (curretnContractReportPeriod.toLowerCase() == "месяц") {
      const curDay = nowDate.getDay();
      const readyDay = 12;
      if (curDay < readyDay) {
        nowDate.setMonth(nowDate.getMonth() - 2);
      } else if (curDay >= readyDay) {
        nowDate.setMonth(nowDate.getMonth() - 1);
      }

      for (let d = beginDate; d <= nowDate; d.setMonth(d.getMonth() + 1)) {
        const fullYear = d.getFullYear();
        const lastDay = new Date(fullYear, d.getMonth() + 1, 0);
        const curMonthString = ("0" + (d.getMonth() + 1)).slice(-2);
        const firstDayString = `01.${curMonthString}.${fullYear}`;
        const lastDayString = `${lastDay.getDate()}.${curMonthString}.${fullYear}`;
        periodsArr.push({
          qtNum: d.getMonth() + 1,
          qtfirstDay: this.getTimeFromString(firstDayString),
          qtLastDay: this.getTimeFromString(lastDayString),
          qtName: `${firstDayString} - ${lastDayString}`,
        });
      }
    }

    this.sortBy(periodsArr, "qtfirstDay", "DESC");

    for (let period of periodsArr) {
      this.companyReports.push({
        periodName: period.qtName,
        periodBegin: period.qtfirstDay,
        periodEnd: period.qtLastDay,
      });
    }
  }

  getReportFile(
    getMethod: string,
    reportDateRange: { start: Date; end: Date } | null,
    periodBegin: number = 0,
    periodEnd: number = 0,
    event: Event | null = null
  ) {
    this.loading = true;
    this.btnGetCustomReport = false;

    let reportStartDate: number;
    let reportEndDate: number;
    // Если нет даты начала и конца периода, пытаемся взять даты из полей ввода
    if (periodBegin == 0 && periodEnd == 0) {
      reportStartDate = Math.floor(reportDateRange!.start.getTime() / 1000);
      reportEndDate = Math.floor(reportDateRange!.end.getTime() / 1000);
    } else {
      reportStartDate = periodBegin;
      reportEndDate = periodEnd;
    }

    if (reportStartDate > reportEndDate)
      [reportStartDate, reportEndDate] = [reportEndDate, reportStartDate];

    this._contractService
      .purchasePdf(
        this.currentContract?.uuid || "",
        reportStartDate,
        reportEndDate
      )
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        () => {
          setTimeout(() => {
            this._contractService
              .getPdf(
                this.currentContract?.uuid || "",
                reportStartDate,
                reportEndDate
              )
              .pipe(takeUntil(this.ngUnsubscribe$))
              .subscribe((fileBinary: any) => {
                const blob: any = new Blob([fileBinary], {
                  type: "application/pdf",
                });
                if (getMethod == "download") {
                  this.loading = false;
                  this.btnGetCustomReport = true;
                  fileSave.saveAs(
                    blob,
                    `${this.currentContract?.uuid}_${reportStartDate}_${reportEndDate}.pdf`
                  );
                } else if (getMethod == "view") {
                  this.loading = false;
                  this.btnGetCustomReport = true;
                  const url = window.URL.createObjectURL(blob);
                  window.open(url);
                }
              }),
              (error: any) => console.log("Ошибка скачивания файла", error),
              () => console.info("Файл скачан успешно");
          }, 6000);
        },
        (error: any) => {
          console.error(error);
        }
      );
  }

  getActs(currentContractUUID: string) {
    if (!currentContractUUID) return;
    this._actService
      .getActsByContract(currentContractUUID)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((resp: CommonRespMess) => {
        this.currentContractActs = resp.message;
      });
  }

  getActFile(actUuid: string, actDate: string, getMethod: string) {
    this.loading = true;
    this._actService.purchaseActPdf(actUuid, actDate).subscribe(
      (resp: CommonRespMess) => {
        if (resp.error) {
          this._errorHandler({
            error: 404,
            statusText: resp.message,
            name: "HttpErrorResponse",
            message: resp.message,
            ok: false,
            headers: new HttpHeaders(),
            status: 204,
            url: null,
            type: HttpEventType.ResponseHeader,
          });
        } else {
          setTimeout(() => {
            this._actService
              .getActPdf(actUuid, actDate)
              .pipe(takeUntil(this.ngUnsubscribe$))
              .subscribe((fileBinary: any) => {
                let blob: any = new Blob([fileBinary], {
                  type: "application/pdf",
                });
                if (getMethod == "download") {
                  this.loading = false;
                  fileSave.saveAs(
                    blob,
                    `act_${actUuid}_${this.dateFormat.format(
                      parseInt(actDate) * 1000
                    )}.pdf`
                  );
                } else if (getMethod == "view") {
                  this.loading = false;
                  const url = window.URL.createObjectURL(blob);
                  window.open(url);
                }
              }),
              (error: any) => console.log("Ошибка скачивания файла", error),
              () => console.info("Файл скачан успешно");
          }, 6000);
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  private _errorHandler(error: HttpErrorResponse) {
    this._errorService.handle(error.message);
    return throwError(() => error.message);
  }

  sortBy(obj: any, sortField: string, sortOrder: string = "ASC"): any {
    let sortedObj;
    if (sortOrder === "ASC") {
      sortedObj = obj.sort((a: any, b: any) => {
        //console.log(typeof a[sortField]);
        if (typeof a[sortField] == "number") {
          return a[sortField] - b[sortField];
        } else if (typeof a[sortField] == "string") {
          if (a[sortField] > b[sortField]) return 1;
          if (a[sortField] < b[sortField]) return -1;
          return 0;
        } else {
          return obj;
        }
      });
    } else if (sortOrder === "DESC") {
      sortedObj = obj.sort((a: any, b: any) => {
        //console.log(typeof a[sortField]);
        if (typeof a[sortField] == "number") {
          return b[sortField] - a[sortField];
        } else if (typeof a[sortField] == "string") {
          if (a[sortField] > b[sortField]) return -1;
          if (a[sortField] < b[sortField]) return 1;
          return 0;
        } else {
          return obj;
        }
      });
    } else {
      sortedObj = obj;
    }
    return sortedObj;
  }
}
