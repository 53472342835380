import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./guards/auth.guard";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AuthLoginComponent } from "./pages/auth-login/auth-login.component";
import { AuthRegisterComponent } from "./pages/auth-register/auth-register.component";
import { AuthRestoreComponent } from "./pages/auth-restore/auth-restore.component";
import { AuthRequestComponent } from "./pages/auth-request/auth-request.component";
import { LkContactComponent } from "./pages/lk-contact/lk-contact.component";
import { LkContractComponent } from "./pages/lk-contract/lk-contract.component";
import { LkItsComponent } from "./pages/lk-its/lk-its.component";
import { LkLicenceComponent } from "./pages/lk-licence/lk-licence.component";
import { LkSoprovojdenieComponent } from "./pages/lk-soprovojdenie/lk-soprovojdenie.component";
import { LkUserprofileComponent } from "./pages/lk-userprofile/lk-userprofile.component";
import { SiteLayoutComponent } from "./layouts/site-layout/site-layout.component";

const routes: Routes = [
  {
    path: "",
    component: AuthLayoutComponent,
    children: [{ path: "", redirectTo: "/auth/login", pathMatch: "full" }],
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    children: [
      { path: "", redirectTo: "/auth/login", pathMatch: "full" },
      { path: "login", component: AuthLoginComponent },
      { path: "register", component: AuthRegisterComponent },
      { path: "restore", component: AuthRestoreComponent },
      { path: "request", component: AuthRequestComponent },
      { path: "contact", component: LkContactComponent },
    ],
  },
  {
    path: "lk",
    component: SiteLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/lk/soprovjdenie", pathMatch: "full" },
      { path: "contracts", component: LkContractComponent },
      { path: "soprovjdenie", component: LkSoprovojdenieComponent },
      { path: "licenсe", component: LkLicenceComponent },
      { path: "its", component: LkItsComponent },
      { path: "contact", component: LkContactComponent },
      { path: "user_profile", component: LkUserprofileComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
