import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import { License } from "../../shared/license.interface";
import { LicenseService } from "../../shared/services/license.service";
import { companyFeatureSelector } from "../../reducers/company";
import { Observable, Subject, takeUntil } from "rxjs";

type LicenseFields = keyof License;

@Component({
  selector: "app-lk-licence",
  templateUrl: "./lk-licence.component.html",
  styleUrls: ["./lk-licence.component.scss"],
})
export class LkLicenceComponent implements OnInit {
  companyUUID$: Observable<string> = this._store.select(companyFeatureSelector);
  displayedColumns: LicenseFields[] = ["product_name", "uuid"];
  licenses: License[] = [];
  loading = false;
  ngUnsubscribe$ = new Subject<void>();

  constructor(private _licenseService: LicenseService, private _store: Store) {}

  ngOnInit(): void {
    this.loading = true;
    this._store
      .select(companyFeatureSelector)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: (companiUUID: string) => {
          this.getCompanyLicenses(companiUUID);
        },
      });
  }

  getLicenses(): void {
    this.loading = true;
    this._licenseService
      .getLicenses()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((licenses) => {
        this.loading = false;
        this.licenses = licenses.message;
      });
  }

  getCompanyLicenses(companyUUID$: string) {
    this.loading = true;
    if (!companyUUID$) {
      this.getLicenses();
    } else {
      this._licenseService
        .getCompanyLicenses(companyUUID$)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe({
          next: (licenses) => {
            this.loading = false;
            this.licenses = licenses.message;
          },
          error: (e) => console.error(e),
        });
    }
    //  this._licenseService.getCompanyLicenses(companiUUID)
    //  .subscribe(licenses => this.licenses = licenses.message);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
