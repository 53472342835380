<h1 mat-dialog-title>Согласие на обработку персональных данных</h1>
<mat-dialog-content>
  Настоящим в&nbsp;соответствии с&nbsp;Федеральным законом &#8470;&nbsp;152-ФЗ
  &laquo;О&nbsp;персональных данных&raquo; от&nbsp;27.07.2006 года свободно,
  своей волей и&nbsp;в&nbsp;своем интересе выражаю свое безусловное согласие
  на&nbsp;обработку моих персональных данных ООО &laquo;Центр консалтинговых
  проектов&raquo; (ОГРН 1095190002126, ИНН 5190199655), зарегистрированным
  в&nbsp;соответствии с&nbsp;законодательством&nbsp;РФ по&nbsp;адресу:
  183039&nbsp;Мурманск, улица Академика Книповича 19А, 1й&nbsp;этаж (далее
  по&nbsp;тексту&nbsp;&mdash; Оператор), со&nbsp;следующими условиями:
  <br />
  <ol style="margin-left: 15px">
    <li>
      Настоящее Согласие выдано мною на&nbsp;обработку следующих персональных
      данных:
      <ul style="list-style: circle">
        <li>фамилия, имя, отчество;</li>
        <li>название организации;</li>
        <li>номер контактного телефона.</li>
      </ul>
    </li>
    <li>
      Согласие дано Оператору для совершения следующих действий с&nbsp;моими
      персональными данными с&nbsp;использованием средств автоматизации и/или
      без использования таких средств:
      <ul>
        <li>уточнение (обновление, изменение);</li>
        <li>обезличивание;</li>
        <li>
          использование для предоставления мне запрашиваемых услуг как
          неавтоматизированными, так и&nbsp;автоматизированными способами.
        </li>
      </ul>
    </li>
    <li>
      Данное согласие дается Оператору для обработки моих персональных данных
      в&nbsp;следующих целях:
      <ul>
        <li>
          обработка моего обращения к&nbsp;менеджеру Оператора
          и&nbsp;установление со&nbsp;мной связи по&nbsp;теме обращения;
        </li>
        <li>
          предоставление мне информации, в&nbsp;том числе рекламной:
          об&nbsp;акциях, мероприятиях, товарах, услугах, работах Оператора.
        </li>
      </ul>
    </li>
    <li>
      Третьи лица не&nbsp;обрабатывают персональные данные по&nbsp;поручению
      Оператора.
    </li>
    <li>
      Трансграничная передача персональных данных Оператором
      не&nbsp;осуществляется.
    </li>
    <li>
      Настоящее согласие действует до&nbsp;момента его отзыва путем направления
      соответствующего уведомления на&nbsp;электронный адрес info@it-murman.ru.
      В&nbsp;случае отзыва мною согласия на&nbsp;обработку персональных данных
      Оператор вправе продолжить обработку персональных данных без моего
      согласия при наличии оснований, указанных в&nbsp;пунктах 2&nbsp;&mdash;
      11&nbsp;части 1&nbsp;статьи&nbsp;6, части 2&nbsp;статьи
      10&nbsp;и&nbsp;части 2&nbsp;статьи 11&nbsp;Федерального закона
      &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo;
      от&nbsp;27.07.2006 г.
    </li>
  </ol>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Скрыть</button>
  <button mat-button color="warn" [mat-dialog-close]="false">Отклонить</button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Принять
  </button>
</mat-dialog-actions>
