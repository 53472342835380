import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.scss"],
  encapsulation: ViewEncapsulation.None, //  Отключение замыкания стилей компонента на самого себя (влияет на до. комп.)
})
export class AuthLayoutComponent {
  constructor() {}
}
