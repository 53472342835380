import { Component, OnInit } from "@angular/core";

import { UserService } from "../../shared/services/user.service";
import { UserDetails } from "../../shared/user";
import { UsrRoleRenamePipe } from "../../pipes/usr-role-rename.pipe";

@Component({
  selector: "app-lk-userprofile",
  templateUrl: "./lk-userprofile.component.html",
  styleUrls: ["./lk-userprofile.component.scss"],
})
export class LkUserprofileComponent implements OnInit {
  rLabel: string = "Мой профиль";
  authUser?: UserDetails;

  constructor(private _userService: UserService) {}

  ngOnInit(): void {
    this._userService
      .getUserDetails()
      .subscribe((authUser) => (this.authUser = authUser.message));
  }
}
