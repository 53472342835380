import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  //endpoint: string = 'http://192.168.0.77:3201/api/auth';
  endpoint: string = environment.apiUrl;
  constructor(private _router: Router, private _http: HttpClient) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.getToken();
    if (token === null) {
      this._router.navigate(['/auth']);
      return false;
    } else {
      return true;
    }
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  checkToken() {
    return this._http
      .get<any>(`${this.endpoint}/ping`)
      .subscribe(
        (res) => {
          return res === 'PING' ? true : false;
        }
      )
  }

}
