import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

declare var ymaps: any;

@Injectable({
  providedIn: "root",
})
export class MapLoaderService {
  scriptYmaps?: HTMLScriptElement;
  map$: Subject<any>;

  constructor() {
    this.map$ = new Subject<any>();
  }

  getMap(element: string, coords: number[]): Observable<any> {
    if (!this.scriptYmaps) {
      this.scriptYmaps = document.createElement("script");
      this.scriptYmaps.src =
        "https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=00f1a6ff-e9fb-440f-acc4-5e6c68c05314";
      document.body.appendChild(this.scriptYmaps);
      this.scriptYmaps.onload = function () {
        ymaps.ready(createMap);
      };
    } else {
      createMap();
    }

    function createMap() {
      const map = new ymaps.Map(element, {
        center: coords,
        zoom: 17,
      });

      let myGeoObjectMur = new ymaps.GeoObject(
        {
          geometry: {
            // Описание геометрии.
            type: "Point",
            coordinates: [68.958889, 33.079722],
          },
          properties: {
            // Свойства.
            iconContent: "Офис Мурманск", // Контент метки.
          },
        },
        {
          // Опции.
          preset: "islands#orangeStretchyIcon", // Иконка метки будет растягиваться под размер ее содержимого.
        }
      );

      let myGeoObjectSpb = new ymaps.GeoObject(
        {
          // Описание геометрии.
          geometry: {
            type: "Point",
            coordinates: [60.013486, 30.308488],
          },
          // Свойства.
          properties: {
            // Контент метки.
            iconContent: "Офис СПб",
          },
        },
        {
          preset: "islands#orangeStretchyIcon",
        }
      );

      map.geoObjects.add(myGeoObjectMur).add(myGeoObjectSpb);
    }

    return this.map$;
  }
}
