import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usrRoleRename',
  pure: false
})
export class UsrRoleRenamePipe implements PipeTransform {

  transform(roleName: string = ''): string {
    if (roleName == 'admin') return 'Администратор';
    else if (roleName == 'user') return 'Пользователь';
    else return 'Роль не задана';
  }
}

