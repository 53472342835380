import { environment } from "src/environments/environment";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CommonRespMess } from '../common-resp-mess.interface';
@Injectable({
  providedIn: 'root'
})
export class UserService {

 // endpoint: string = 'https://192.168.0.77:3201/api';
  private _endpoint: string = environment.apiUrl;


  constructor(private _httpClient: HttpClient) { }

  getUserDetails(): Observable<CommonRespMess> {
    return this._httpClient.get<CommonRespMess>(`${this._endpoint}/users/info`);
  }

}

