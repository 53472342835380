import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";

import { companyFeatureSelector } from "../../reducers/company";
import { Contract } from "../../shared/UserCotract";
import { ContractService } from "../../shared/services/contract.service";
import { Subject, takeUntil } from "rxjs";
import { ContractDetailsDialogComponent } from "./contract-details-dialog/contract-details-dialog.component";

type contractFields = keyof Contract;

export enum ContractEnum {
  abonContracts,
  allContracts,
}

@Component({
  selector: "app-lk-soprovojdenie",
  templateUrl: "./lk-soprovojdenie.component.html",
  styleUrls: ["./lk-soprovojdenie.component.scss"],
})
export class LkSoprovojdenieComponent implements OnInit {
  contractEnum = ContractEnum;
  dispalyedContractsType = ContractEnum.abonContracts;
  currencyFormat = new Intl.NumberFormat("ru-ru", {
    style: "currency",
    currency: "RUB",
  });
  currentContract?: Contract;
  dateFormat = new Intl.DateTimeFormat("ru-RU");
  displayedColumns: contractFields[] = [
    "uuid",
    "contract_date",
    "end_date",
    "period",
    "summ",
    "balance",
    "id",
  ];
  loading = false;
  ngUnsubscribe$ = new Subject<void>();
  selectedContract?: string;
  usrContracts!: Contract[];

  constructor(
    private _contractService: ContractService,
    private _store: Store,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._store
      .select(companyFeatureSelector)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: (resp: string) => {
          this.getCompanyContracts(resp);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getContrList() {
    this.loading = true;
    this._contractService
      .getContracts()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: (contrList) => {
          if (this.dispalyedContractsType == ContractEnum.abonContracts) {
            let filteredContrat = contrList.message.filter(
              (item: Contract) => item.type == "Абонентский договор"
            );
            this.usrContracts = this.sortBy(filteredContrat, "uuid", "DESC");
            this.loading = false;
          } else {
            this.usrContracts = this.usrContracts = this.sortBy(
              contrList.message,
              "uuid",
              "DESC"
            );
          }
          this.loading = false;
        },
        error: (e) => console.error(e),
      });
  }

  getCompanyContracts(companyUUID: string) {
    this.loading = true;
    if (!companyUUID) {
      this.getContrList();
    } else {
      this._contractService
        .getCompanyContracts(companyUUID)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe({
          next: (contrList) => {
            if (this.dispalyedContractsType == ContractEnum.abonContracts) {
              let filteredContrat: Contract[] = contrList.message.filter(
                function (item: Contract) {
                  return item.type == "Абонентский договор";
                }
              );
              this.usrContracts = this.sortBy(filteredContrat, "uuid", "DESC");
            } else {
              this.usrContracts = this.sortBy(
                contrList.message,
                "uuid",
                "DESC"
              );
            }
            this.loading = false;
          },
          error: (e) => console.error(e),
        });
    }
  }

  openDialog(event: MouseEvent, rowId: string) {
    if (this.dispalyedContractsType == ContractEnum.allContracts) return;

    this.selectedContract = rowId;
    this.currentContract = this.usrContracts.filter(
      (contr) => contr.uuid == rowId
    )[0];

    this.dialog.open(ContractDetailsDialogComponent, {
      autoFocus: "first-header",
      data: [this.currentContract],
      maxWidth: 576,
      width: "content",
    });
  }

  filterContracs(event: any) {
    this.dispalyedContractsType = event.value;
    // this.changeColumns();
    this._store
      .select(companyFeatureSelector)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: (resp: string) => {
          this.getCompanyContracts(resp);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  sortBy(obj: any, sortField: string, sortOrder: string = "ASC"): any {
    let sortedObj;
    if (sortOrder == "ASC") {
      sortedObj = obj.sort((a: any, b: any) => {
        if (typeof a[sortField] == "number") {
          return a[sortField] - b[sortField];
        } else if (typeof a[sortField] == "string") {
          if (a[sortField] > b[sortField]) return 1;
          if (a[sortField] < b[sortField]) return -1;
          return 0;
        } else {
          return obj;
        }
      });
    } else if (sortOrder == "DESC") {
      sortedObj = obj.sort((a: any, b: any) => {
        if (typeof a[sortField] == "number") {
          return b[sortField] - a[sortField];
        } else if (typeof a[sortField] == "string") {
          if (a[sortField] > b[sortField]) return -1;
          if (a[sortField] < b[sortField]) return 1;
          return 0;
        } else {
          return obj;
        }
      });
    } else {
      sortedObj = obj;
    }
    return sortedObj;
  }

  // Для изменения состава колонок при использовании стандартного компонента таблиц Material UI
  // changeColumns() {
  //   const curView = this.dispalyedContractsType;
  //   if (curView === ContractEnum.allContracts) {
  //     this.displayedColumns = [
  //       "uuid",
  //       "contract_date",
  //       "end_date",
  //       "type",
  //       "summ",
  //       "balance",
  //     ];
  //   } else if (curView === ContractEnum.abonContracts) {
  //     this.displayedColumns = [
  //       "uuid",
  //       "contract_date",
  //       "end_date",
  //       "period",
  //       "summ",
  //       "balance",
  //       "id",
  //     ];
  //   }
  // }

  // Функция перенесена в /contract-details-dialog/contract-details-dialog.component.ts
  // getProducts(currentContractId: number) {
  //   if (!currentContractId) return;
  //   this._store.select(companyFeatureSelector).subscribe({
  //     next: (resp: string) => {
  //       if (resp == "") {
  //         this._contractService
  //           .getProducts()
  //           .pipe(takeUntil(this.ngUnsubscribe$))
  //           .subscribe((prodList: CommonRespMess) => {
  //             this.companyProducts = prodList.message.filter(function (
  //               item: Product
  //             ) {
  //               return item.contract_id == currentContractId;
  //             });
  //           });
  //       } else {
  //         this._contractService
  //           .getCompanyProducts(resp)
  //           .pipe(takeUntil(this.ngUnsubscribe$))
  //           .subscribe((prodList: CommonRespMess) => {
  //             this.companyProducts = prodList.message.filter(function (
  //               item: Product
  //             ) {
  //               return item.contract_id == currentContractId;
  //             });
  //           });
  //       }
  //     },
  //     error: (error) => {
  //       console.log(error);
  //     },
  //   });
  // }

  // Функция перенесена в /contract-details-dialog/contract-details-dialog.component.ts
  // getActs(currentContractUUID: string) {
  //   if (!currentContractUUID) return;
  //   this._actService
  //     .getActsByContract(currentContractUUID)
  //     .pipe(takeUntil(this.ngUnsubscribe$))
  //     .subscribe((resp: CommonRespMess) => {
  //       this.currentContractActs = resp.message;
  //       let tstContr = resp.message;
  //     });
  // }

  // Функция перенесена в /contract-details-dialog/contract-details-dialog.component.ts
  // getActFile(act_uuid: string, actDate: string, getMethod: string) {
  //   this._actService.purchasePdf(act_uuid, actDate).subscribe(() => {
  //     setTimeout(() => {
  //       this._actService
  //         .getPdf(act_uuid, actDate)
  //         .pipe(takeUntil(this.ngUnsubscribe$))
  //         .subscribe((fileBinary: any) => {
  //           let blob: any = new Blob([fileBinary], { type: "application/pdf" });
  //           if (getMethod == "download") {
  //             fileSave.saveAs(
  //               blob,
  //               `act_${act_uuid}_${this.dateFormat.format(
  //                 parseInt(actDate) * 1000
  //               )}.pdf`
  //             );
  //           } else if (getMethod == "view") {
  //             const url = window.URL.createObjectURL(blob);
  //             window.open(url);
  //           }
  //         });
  //     });
  //   }, 10000);
  // }

  // Функция перенесена в /contract-details-dialog/contract-details-dialog.component.ts
  // getTimeFromString = (date: string) => {
  //   const dateArr = date.split(".");
  //   const parsedDate = new Date(
  //     parseInt(dateArr[2]),
  //     parseInt(dateArr[1]) - 1,
  //     parseInt(dateArr[0])
  //   );
  //   return parsedDate.getTime() / 1000;
  // };
  // getDateFromString = (date: string) => {
  //   let dateArr = date.split(".");
  //   return new Date(
  //     parseInt(dateArr[2]),
  //     parseInt(dateArr[1]) - 1,
  //     parseInt(dateArr[0])
  //   );
  // };

  // Функция перенесена в /contract-details-dialog/contract-details-dialog.component.ts
  // getFile(getMethod: string, periodBegin: number = 0, periodEnd: number = 0) {
  //   let preload = document.querySelector(".report-preloader");
  //   preload?.classList.remove("hidden");

  //   let reportStartDate: number;
  //   let reportEndDate: number;

  //   // Если нет даты начала и конца периода, пытаемся взять даты из полей ввода
  //   if (periodBegin == 0 && periodEnd == 0) {
  //     reportStartDate = Math.floor(this.datePickerStart.date.getTime() / 1000);
  //     reportEndDate = Math.floor(this.datePickerEnd.date.getTime() / 1000);
  //     // Если даты из полей ввода то отключаем соотв методу копку
  //   } else {
  //     reportStartDate = periodBegin;
  //     reportEndDate = periodEnd;
  //   }

  //   if (reportStartDate > reportEndDate)
  //     [reportStartDate, reportEndDate] = [reportEndDate, reportStartDate];

  //   this._contractService
  //     .purchasePdf(this.selectedContract || "", reportStartDate, reportEndDate)
  //     .pipe(takeUntil(this.ngUnsubscribe$))
  //     .subscribe(
  //       () => {
  //         setTimeout(() => {
  //           this._contractService
  //             .getPdf(
  //               this.selectedContract || "",
  //               reportStartDate,
  //               reportEndDate
  //             )
  //             .pipe(takeUntil(this.ngUnsubscribe$))
  //             .subscribe((fileBinary: any) => {
  //               preload?.classList.add("hidden");
  //               const blob: any = new Blob([fileBinary], {
  //                 type: "application/pdf",
  //               });
  //               if (getMethod == "download") {
  //                 fileSave.saveAs(
  //                   blob,
  //                   `${this.selectedContract}_${reportStartDate}_${reportEndDate}.pdf`
  //                 );
  //               } else if (getMethod == "view") {
  //                 const url = window.URL.createObjectURL(blob);
  //                 window.open(url);
  //               }
  //             }),
  //             (error: any) => console.log("Ошибка скачивания файла", error),
  //             () => console.info("Файл скачан успешно");
  //         }, 4000);
  //       },
  //       (error: any) => {
  //         console.error(error);
  //       }
  //     );
  // }

  // Функция перенесена в /contract-details-dialog/contract-details-dialog.component.ts
  // getReports(
  //   currentContrBeginDate: string,
  //   curretnContractReportPeriod: string
  // ): void {
  //   const getQuater = (date: Date) => Math.floor(date.getMonth() / 3 + 1);

  //   this.companyReports = [];
  //   const beginDate = this.getDateFromString(currentContrBeginDate);

  //   const quatersArr: any = [];

  //   if (curretnContractReportPeriod.toLocaleLowerCase() == "квартал") {
  //     const contrQuaterNum = getQuater(beginDate);
  //     const nowDate = new Date();
  //     const contrYear = beginDate.getFullYear();
  //     const quaters = [
  //       { qtNum: 1, qtfirstDay: "01.01.", qtLastDay: "31.03." },
  //       { qtNum: 2, qtfirstDay: "01.04.", qtLastDay: "31.05." },
  //       { qtNum: 3, qtfirstDay: "01.06.", qtLastDay: "30.09." },
  //       { qtNum: 4, qtfirstDay: "01.10.", qtLastDay: "31.12." },
  //     ];

  //     for (let y = beginDate.getFullYear(); y <= nowDate.getFullYear(); y++) {
  //       for (let q of quaters) {
  //         if (
  //           !(
  //             (q.qtNum < contrQuaterNum && y == contrYear) ||
  //             (q.qtNum >= Math.floor(nowDate.getMonth() / 3 + 1) &&
  //               y == nowDate.getFullYear())
  //           )
  //         ) {
  //           const firstDateString = q.qtfirstDay + y;
  //           const lastDateString = q.qtLastDay + y;
  //           quatersArr.push({
  //             qtNum: q.qtNum,
  //             qtfirstDay: this.getTimeFromString(firstDateString),
  //             qtLastDay: this.getTimeFromString(lastDateString),
  //             qtName: firstDateString + "-" + lastDateString,
  //           });
  //         }
  //       }
  //     }
  //   } else if (curretnContractReportPeriod.toLowerCase() == "месяц") {
  //     const nowDate = new Date();
  //     for (let d = beginDate; d <= nowDate; d.setMonth(d.getMonth() + 1)) {
  //       const fullYear = d.getFullYear();
  //       const lastDay = new Date(fullYear, d.getMonth() + 1, 0);
  //       const curMonthString = ("0" + (d.getMonth() + 1)).slice(-2);
  //       const firstDayString = `01.${curMonthString}.${fullYear}`;
  //       const lastDayString = `${lastDay.getDate()}.${curMonthString}.${fullYear}`;
  //       quatersArr.push({
  //         qtNum: d.getMonth() + 1,
  //         qtfirstDay: this.getTimeFromString(firstDayString),
  //         qtLastDay: this.getTimeFromString(lastDayString),
  //         qtName: `${firstDayString}-${lastDayString}`,
  //       });
  //     }
  //   }

  //   this.sortBy(quatersArr, "qtfirstDay", "DESC");

  //   for (let p of quatersArr) {
  //     this.companyReports.push({
  //       periodName: p.qtName,
  //       periodBegin: p.qtfirstDay,
  //       periodEnd: p.qtLastDay,
  //     });
  //   }
  // }
}
