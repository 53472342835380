<app-global-error></app-global-error>

<div class="container">
  <div
    class="row my-3 my-lg-5 d-flex align-items-center justify-content-between"
  >
    <div class="col-4">
      <div class="logo">
        <a href="/auth/login" class="svg"
          ><object
            type="image/svg+xml"
            data="./assets/img/company_logo_svg.svg"
          >
            <img
              src="./assets/img/company_logo_svg.svg"
              alt="Центр консалтинговых проектов"
            /></object
        ></a>
      </div>
    </div>
    <div class="col-1 icon-help">
      <a
        href="/assets/doc/instruction_0.0.1.pdf"
        class="text-decoration-none d-flex flex-nowrap align-items-center justify-content-end pt-5 pt-md-0"
        ><mat-icon>help</mat-icon>&nbsp;<span>Инструкция</span></a
      >
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<div class="container">
  <hr />
  <div class="row" style="float: right">
    <div class="col my-3 my-lg-5">
      <a href="/auth/contact">Контактная информация</a>
    </div>
  </div>
</div>
