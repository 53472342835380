import { environment } from "src/environments/environment";
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, Observable, retry, throwError } from 'rxjs';

import { CommonRespMess } from '../common-resp-mess.interface';
import { ErrorService } from "./error.service";
@Injectable({
  providedIn: 'root'
})
export class ContractService {

  private _endpoint: string = environment.apiUrl;

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  public getContracts(): Observable<CommonRespMess> {
    return this._http.get<CommonRespMess>(`${this._endpoint}/contracts/get_full_contract_list`)
    .pipe(
      retry(3),
      catchError(this._errorHandler.bind(this)),
    );
  }

  public getCompanyContracts(companyUUID: string): Observable<CommonRespMess> {
    return this._http.post<CommonRespMess>(`${this._endpoint}/contracts/get_contract_list_by_contragent`, { "contragent_uuid": companyUUID });
  }

  public getProducts(): Observable<CommonRespMess> {
    return this._http.get<CommonRespMess>(`${this._endpoint}/contracts/get_full_software_products_list`);
  }

  public getCompanyProducts(companyUUID: any): Observable<CommonRespMess> {
    return this._http.post<CommonRespMess>(`${this._endpoint}/contracts/get_software_products_by_contragent`, { "contragent_uuid": companyUUID });
  }

  public purchasePdf(contractId: string, reportStartDate: number, reportEndDate: number): any {
    return this._http.post(`${this._endpoint}/reports/get_report`, { "type": "contract", "appointment": "storage", "uuid": contractId, "start": reportStartDate, "end": reportEndDate });
  }

  public purchasePdfByEmail(contractId: string, reportStartDate: number, reportEndDate: number): any {
    return this._http.post(`${this._endpoint}/reports/get_new`, { "type": "contract", "appointment": "storage", "uuid": contractId, "start": reportStartDate, "end": reportEndDate });
  }

  public getPdf(contractId: string, reportStartDate: number, reportEndDate: number): any {
    return this._http.post(`${this._endpoint}/reports/files/download/report`, { "uuid": contractId, "start": reportStartDate, "end": reportEndDate }, { responseType: 'blob' })
    .pipe(
      retry(3),
      catchError(this._errorHandler.bind(this)),
    );
  }

  private _errorHandler ( error: HttpErrorResponse ) {
    this._errorService.handle(error.message);
    return throwError( () => error.message );
  }

}
