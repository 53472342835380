import { Component, OnInit, Input } from "@angular/core";

import { UserDetails } from "../../shared/user";
@Component({
  selector: "app-lk-overhead",
  templateUrl: "./lk-overhead.component.html",
  styleUrls: ["./lk-overhead.component.scss"],
})
export class LkOverheadComponent {
  @Input() authUser?: UserDetails;

  constructor() {}
}
