import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'actPeriodCard',
  pure: false
})
export class ActPeriodCardPipe implements PipeTransform {


  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: number): SafeHtml {

    let numOffIntrvls = 12 / value;
    let intrvlHTML = '';

    console.log(numOffIntrvls);

    for (let i = 0; i <= numOffIntrvls; i++) {
      intrvlHTML = intrvlHTML + `<div class="period-ind-item"></div>`;
      //console.log(intrvlHTML);
    }

    let tmplHtml = `<div class="period-ind quater">` + intrvlHTML + `</div>`;

    let actCard = '<span style="color:#FF30000;">' + value + '</span>';

    return this._sanitizer.bypassSecurityTrustHtml(actCard);

  }

}
