import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Subject, takeUntil } from "rxjs";

import { companyFeatureSelector } from "../../reducers/company";
import { ItsDialogComponent } from "./its-dialog/its-dialog.component";
import { ItsShort } from "../../shared/its.interface";
import { ItsService } from "../../shared/services/its.service";

type ItsFields = keyof ItsShort;

@Component({
  selector: "app-lk-its",
  templateUrl: "./lk-its.component.html",
  styleUrls: ["./lk-its.component.scss"],
})
export class LkItsComponent implements OnInit {
  displayedColumns: ItsFields[] = ["nomenclature", "end_date"];
  itses: ItsShort[] = [];
  loading = false;
  ngUnsubscribe$ = new Subject<void>();

  constructor(
    private _itsService: ItsService,
    private _store: Store,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._store
      .select(companyFeatureSelector)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: (companiUUID: string) => {
          this.getCompanyItses(companiUUID);
        },
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getItses(): void {
    this.loading = true;
    this._itsService
      .getItses()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((itses) => {
        this.loading = false;
        this.itses = itses.message;
      });
  }

  getCompanyItses(companyUUID: string) {
    this.loading = true;
    if (!companyUUID) {
      this.getItses();
    } else {
      this._itsService
        .getCompanyItses(companyUUID)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((itses) => {
          this.loading = false;
          this.itses = itses.message;
        });
    }
  }

  openDialog(event: MouseEvent) {
    const dialogRef = this.dialog.open(ItsDialogComponent, {
      maxWidth: 575,
    });
  }
}
