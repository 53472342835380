import { environment } from "src/environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, retry, throwError } from "rxjs";

import { CommonRespMess } from "../common-resp-mess.interface";
import { ErrorService } from "./error.service";

@Injectable({
  providedIn: "root",
})
export class ActService {
  private _endpoint: string = environment.apiUrl;

  constructor(private _http: HttpClient, private _errorService: ErrorService) {}

  public getActsByContract(contract_uuid: string): Observable<CommonRespMess> {
    return this._http
      .post<CommonRespMess>(
        `${this._endpoint}/contracts/get_acts_by_contract`,
        { contract_uuid: contract_uuid }
      )
      .pipe(retry(2), catchError(this._errorHandler.bind(this)));
  }

  public purchaseActPdf(actId: string, actDate: string): any {
    return this._http
      .post(`${this._endpoint}/reports/get_act`, {
        appointment: "storage",
        uuid: actId,
        date: parseInt(actDate),
      })
      .pipe(retry(2), catchError(this._errorHandler.bind(this)));
  }

  public getActPdf(actId: string, actDate: string): any {
    return this._http
      .post(
        `${this._endpoint}/reports/files/download/act`,
        { uuid: actId, date: parseInt(actDate) },
        { responseType: "blob" }
      )
      .pipe(retry(2), catchError(this._errorHandler.bind(this)));
  }

  public purchaseReconciliationActPdf(contragent_uuid: string, currentDate: string): any {
    return this._http
      .post(`${this._endpoint}/reports/get_reconciliation_act`, {
        appointment: "storage",
        contragent_uuid: contragent_uuid,
        date: parseInt(currentDate),
      })
      .pipe(retry(2), catchError(this._errorHandler.bind(this)));
  }

  public getReconciliationActPdf(contragent_uuid: string, currentDate: string): any {
    return this._http
      .post(
        `${this._endpoint}/reports/files/download/reconciliation_act`,
        { contragent_uuid: contragent_uuid, date: parseInt(currentDate) },
        { responseType: "blob" }
      )
      .pipe(retry(2), catchError(this._errorHandler.bind(this)));
  }

  private _errorHandler(error: HttpErrorResponse) {
    this._errorService.handle(error.message);
    return throwError(() => error.message);
  }

  // TODO делать проверку наличия акта при запросе {error:true, message: }
  //
}
