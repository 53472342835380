import { Component, OnInit } from "@angular/core";
import { ErrorService } from "../../shared/services/error.service";

@Component({
  selector: "app-global-error",
  templateUrl: "./global-error.component.html",
  styleUrls: ["./global-error.component.scss"],
})
export class GlobalErrorComponent {
  constructor(public _errorService: ErrorService) {}
}
