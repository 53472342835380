<div class="row banner">
  <div class="col-12">
    <a
      href="https://www.youtube.com/channel/UCeCis5Q_eN-nPdrfSTWY1Zg"
      target="_blank"
      ><section class="banner-resp mat-elevation-z2 d-flex align-items-center">
        <div
          class="col-11 col-sm-6 col-md-5 col-lg-6 offset-1 offset-sm-4 offset-md-5"
        >
          <p>
            Youtube канал ЦКП – только самое важное об&nbsp;учёте
            и&nbsp;отчётности в&nbsp;1С
          </p>
        </div>
      </section>
    </a>
  </div>
</div>
