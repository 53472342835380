<div class="container">
  <div class="row align-items-stretch">
    <div class="col col-lg-5 d-none d-md-inline-block">
      <div class="logo vertical-align">
        <object type="image/svg+xml" data="./assets/img/company_logo_svg.svg">
          <img
            src="./assets/img/company_logo_svg.svg"
            alt="Центр консалтинговых проектов"
          />
        </object>
      </div>
    </div>

    <app-company-select
      class="col-12 col-md-3 col-lg-4 mt-2 mt-md-3"
    ></app-company-select>

    <div
      class="col-md-3 col-lg-2 offset-lg-1 d-flex justify-content-end d-none d-md-inline-block"
    >
      <div class="usr-controls-wrapper justify-content-end">
        <div class="usr-details">
          <div class="usr-name text-right">
            <span style="color: dodgerblue; font-size: large"
              >{{ authUser?.name }}&nbsp;{{ authUser?.surname }}</span
            >
            <!-- <a href="/lk/user_profile"
              >{{ authUser?.name }}&nbsp;{{ authUser?.surname }}</a
            > -->
          </div>
          <div class="usr-role">{{ authUser?.position }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
