import { environment } from "src/environments/environment";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { AuthService } from "../../shared/services/auth.service";
import { UserRegCredentials } from "../../shared/user";
import { CommonRespMess } from "../../shared/common-resp-mess.interface";

@Component({
  selector: "app-auth-register",
  templateUrl: "./auth-register.component.html",
  styleUrls: ["./auth-register.component.scss"],
})
export class AuthRegisterComponent implements OnInit {
  //endpoint: string = 'http://192.168.0.77:3201/api/auth'
  actionType = "Активировать аккаунт";
  authToken!: string;
  formReg!: FormGroup;
  headers = new HttpHeaders().set("Content-Type", "application/json");
  isTokenVerif = false;
  loading = false;
  userRegCredentials!: UserRegCredentials;
  restore: number | boolean = false;
  private _endpoint = environment.apiUrl;

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _http: HttpClient,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formReg = this._formBuilder.group(
      {
        action: ["Restore password", []],
        token: [null, []],
        email: new FormControl(null, [Validators.required]),
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
        ]),
        passwordConfirm: new FormControl(null, [Validators.required]),
      },
      {
        validators: [this.confirmPassMatch("password", "passwordConfirm")],
      }
    );

    this._route.queryParams.subscribe((params) => {
      this.authToken = params["authToken"];
      this.restore = params["restore"];

      if (this.restore) this.actionType = "Установить пароль";

      return this._http
        .get<CommonRespMess>(
          `${this._endpoint}/auth/checkToken?token=${this.authToken}`
        )
        .subscribe({
          next: (credList) => {
            this.isTokenVerif = false;
            this.userRegCredentials = credList.message;
            this.formReg.patchValue({ email: this.userRegCredentials.email });
            //localStorage.setItem('usrEmail', this.userRegCredentials.email);
            //console.info('GoodToken');
            //console.log(credList);
          },
          error: (e) => {
            this.isTokenVerif = true;
            console.error(e);
          },
        });
    });
  }

  onSubmit() {
    if (!this.restore) {
      //this.formReg.removeControl('passwordConfirm');
      this._auth.regUser(this.formReg.value);
    } else {
      this.formReg.value.token = localStorage.getItem("access_token");
      //this.formReg.removeControl('passwordConfirm');
      this.formReg.patchValue({
        token: this.authToken,
        action: "Restore password",
      });
      this._auth.restorePassword(this.formReg.value);
    }
  }

  confirmPassMatch(passwd: any, confPasswd: any) {
    return (formGroup: FormGroup) => {
      const passwdControl = formGroup.controls[passwd];
      const confPasswdControl = formGroup.controls[confPasswd];

      if (confPasswdControl.errors && !confPasswdControl.errors["MustMatch"]) {
        return;
      }

      if (passwdControl.value !== confPasswdControl.value) {
        confPasswdControl.setErrors({ MustMatch: true });
      } else {
        confPasswdControl.setErrors(null);
      }
    };
  }

  get f() {
    return this.formReg.controls;
  }
}
