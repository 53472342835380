import { createAction, createReducer, createFeatureSelector, createSelector } from '@ngrx/store'
import { on } from '@ngrx/store';

/*
Прописываем набор доступных действий для модификации хранилища
*/
export const selectCompany = createAction('[COMPANY_SELECTOR] select', (companyID) => ({ companyID }));

export interface CompanySelectorState {
  companyUUID: string
}

export const initialState: CompanySelectorState = {
  companyUUID: ''
}

export const companySelectorReducer = createReducer(
  initialState,
  on(selectCompany, (state, { companyID }) => ({
    ...state,
    companyUUID: companyID
  }))
);

export const featureSelector = createFeatureSelector<CompanySelectorState>('companySelector');

export const companyFeatureSelector = createSelector(
  featureSelector,
  state => state.companyUUID
)