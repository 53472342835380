import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unixTsToDate'
})
export class UnixTsToDatePipe implements PipeTransform {

  dateFormat = Intl.DateTimeFormat.call('ru-ru');

  transform(unixTs: string | undefined): string {
    let dateStr = '';
    if (unixTs == '') {
      dateStr = '22.11.1983';
    } else if (unixTs !== undefined) {
      let date = new Date(parseInt(unixTs) * 1000);
      dateStr = this.dateFormat.format(date);
    }
    return dateStr;
  }

}
