import { environment } from "src/environments/environment";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CommonRespMess } from '../common-resp-mess.interface';
@Injectable({
  providedIn: 'root'
})
export class ItsService {

  private _endpoint: string = environment.apiUrl;

  constructor(private _httpClient: HttpClient) { }

  getItses(): Observable<CommonRespMess> {
    return this._httpClient.get<CommonRespMess>(`${this._endpoint}/contragent/get_full_its`);
  }

  getCompanyItses(companyUUID: string): Observable<CommonRespMess> {
    return this._httpClient.post<CommonRespMess>(`${this._endpoint}/contragent/get_its_by_contragent`, { "contragent_uuid": companyUUID });
  }

}
