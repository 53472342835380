import { Component } from "@angular/core";

@Component({
  selector: "app-banner-social",
  templateUrl: "./banner-social.component.html",
  styleUrls: ["./banner-social.component.scss"],
})
export class BannerSocialComponent {
  constructor() {}
}
