import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEventType, HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "../../shared/services/auth.service";

import { UserDetails } from "../../shared/user";
import { UserService } from "../../shared/services/user.service";
import { ActService } from "src/app/shared/services/act.service";
import { CommonRespMess } from "src/app/shared/common-resp-mess.interface";
import { ErrorService } from "src/app/shared/services/error.service";
import { CompanySelectComponent } from "../../components/company-select/company-select.component";
import { companyFeatureSelector } from "../../reducers/company";
import { Store } from "@ngrx/store";
import { Subject, takeUntil, throwError } from "rxjs";
import * as fileSave from "file-saver";

@Component({
  selector: "app-site-layout",
  templateUrl: "./site-layout.component.html",
  styleUrls: ["./site-layout.component.scss"],
})
export class SiteLayoutComponent implements OnInit {
  headers = new HttpHeaders().set("Content-Type", "application/json");
  userDetails?: UserDetails;
  dateFormat = new Intl.DateTimeFormat("ru-ru");
  loading = false;
  ngUnsubscribe$ = new Subject<void>();
  // nowDate = new Date();
  // curDay = this.nowDate.getDay();

  constructor(
    private _authService: AuthService,
    private _actService: ActService,
    private _userService: UserService,
    private _errorService: ErrorService,
    private _store: Store,
    private _companySelectComponent: CompanySelectComponent
  ) {}

  ngOnInit(): void {
    this._userService.getUserDetails().subscribe((userDetails) => {
      this.userDetails = userDetails.message;
    });
  }

  logout() {
    this._authService.doLogout();
  }

  getReconciliationAct(getMethod: string){
    this._store
      .select(companyFeatureSelector)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: (resp: string) => {
          this.getReconciliationActFile(resp, getMethod);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  getReconciliationActFile(contragent_uuid: string, getMethod: string) {
    this.loading = true;
    //const contragent_uuid = this._companySelectComponent.selectedCompany;
    const currentDate = (Date.now()/1000).toFixed().toString(); //new Date();
    console.log(contragent_uuid + " !!! " + currentDate);
    this._actService.purchaseReconciliationActPdf(contragent_uuid, currentDate).subscribe(
      (resp: CommonRespMess) => {
        if (resp.error) {
          this._errorHandler({
            error: 404,
            statusText: resp.message,
            name: "HttpErrorResponse",
            message: resp.message,
            ok: false,
            headers: new HttpHeaders(),
            status: 204,
            url: null,
            type: HttpEventType.ResponseHeader,
          });
        } else {
          setTimeout(() => {
            this._actService
              .getReconciliationActPdf(contragent_uuid, currentDate)
              .pipe(takeUntil(this.ngUnsubscribe$))
              .subscribe((fileBinary: any) => {
                let blob: any = new Blob([fileBinary], {
                  type: "application/pdf",
                });
                if (getMethod == "download") {
                  this.loading = false;
                  fileSave.saveAs(
                    blob,
                    `reconciliation_act_${contragent_uuid}_${this.dateFormat.format(
                      parseInt(currentDate) * 1000
                    )}.pdf`
                  );
                } else if (getMethod == "view") {
                  this.loading = false;
                  const url = window.URL.createObjectURL(blob);
                  window.open(url);
                }
              }),
              (error: any) => console.log("Ошибка скачивания файла", error),
              () => console.info("Файл скачан успешно");
          }, 6000);
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  private _errorHandler(error: HttpErrorResponse) {
    this._errorService.handle(error.message);
    return throwError(() => error.message);
  }
}
