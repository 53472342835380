<div class="content-wrapper">
  <div class="container">

    <div class="row">
      <div class="col s12 m10 l8">
        <h4>Договоры</h4>
      </div>
    </div>

    <div class="row">
      <div class="col s12">
        <table class="responsive-table highlight">
          <thead>
            <tr>
              <th>Договор</th>
              <th>Дата заключения</th>
              <th>Дата окончания</th>
              <th>Стоимость за период</th>
              <th>Взаиморасчёты</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let usrContr of usrContracts" (click)="showDetails(usrContr.uuid, usrContr.id)"
              [title]="usrContr.comment">
              <td>{{usrContr.printname}}</td>
              <td>{{usrContr.contract_date}}</td>
              <td>{{usrContr.end_date}}</td>
              <td>
                <div class="currency-holder">{{usrContr.summ | rubles}}</div>
              </td>
              <td [ngClass]="usrContr.balance < 0 ? 'red-text' : '' ">
                <div class="currency-holder">{{usrContr.balance | rubles}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Modal Structure -->
<div id="modal1" class="modal" data-target='log_detail'>

  <div class="modal-content">
    <div class="row">
      <div class="col s6">
        <h4>Детали договора</h4>
        <table>
          <tr><b>Тип:</b> </tr>
          <tr>Сумма: </tr>
          <tr>Статус:</tr>
          <tr>Баланс:</tr>
          <tr><a>Отчёт</a></tr>
        </table>
      </div>
    </div>
  </div>

  <div class="row modal-static">
    <div class="col s12 report-controls">
      <div>
        <span>Отчёт за период</span>
      </div>
      <div>
        <input id="reportDateBegin" type="text" class="datepicker" placeholder="Начало">
      </div>
      <div>
        <input id="reportDateEnd" type="text" class="datepicker" placeholder="Конец">
      </div>
      <div>
        <a id="btnDonloadReport" class="btn orange" (click)="getFile('download')">Скачать</a>
        <a id="btnViewReport" class="btn orange" (click)="getFile('view')" style="margin-left:15px;">Просмотреть</a>
      </div>
    </div>
  </div>
  <div class="row center-align report-preloader hidden">
    <div class="progress">
      <div class="indeterminate"></div>
    </div>
  </div>
  <div class="section"></div>
</div>


<!-- <div class="fixed-action-btn" (click)="getContrList()">
  <a class="btn-floating btn-large pulse orange darken-3">
    <i class="large material-icons">chat_bubble_outline</i>
  </a>
</div> -->