<h1
  mat-dialog-title
  class="d-flex align-items-center"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <mat-icon color="accent" style="font-size: larger; margin-right: 15px"
    >description</mat-icon
  >
  <span>
    Договор <b>{{ currentContract?.uuid }}</b> от&nbsp;<b>{{
      currentContract?.contract_date
    }}</b></span
  >
</h1>
<mat-dialog-content>
  <mat-tab-group dynamicHeight>
    <mat-tab label="Общие сведения">
      <div class="row mx-0 my-3">
        <div class="col w-100">
          <table class="commonInfoTable">
            <tr>
              <td>
                <b>Тип:</b> {{ currentContract?.type }}
                {{ currentContract?.per_hour | contractTimeType }}
              </td>
              <td><b>Статус:</b> {{ currentContract?.status }}</td>
            </tr>
            <tr *ngIf="!currentContract?.per_hour">
              <td>
                <b>Стоимость за период:</b>
                {{ currentContract?.summ | rubles }}
              </td>
              <td *ngIf="currentContract?.hours_limit">
                <b>Лимит часов за период:</b>
                {{ currentContract?.hours_limit }}
                ч.
              </td>
            </tr>
            <tr>
              <td>
                <b>Стоимость часа:</b>
                {{ currentContract?.hourly_rate | rubles }}
              </td>
              <!-- <td *ngIf="!currentContract?.per_hour">
                <b>Стоим.часа сверх нормы:</b>
                {{ currentContract?.hourly_rate_over_limit | rubles }}
              </td> -->
              <td>
                <b>Стоим.часа сверх нормы:</b>
                {{ currentContract?.hourly_rate_over_limit | rubles }}
              </td>
            </tr>
            <tr>
              <td><b>Отчётный период:</b> {{ currentContract?.period }}</td>
              <td>
                <b>Баланс:</b>
                <span
                  [ngStyle]="{
                    color: currentContract!.balance < 0 ? 'red' : ''
                  }"
                >
                  {{ currentContract?.balance | rubles }}</span
                >
              </td>
            </tr>
          </table>
          <div class="wrapper" *ngIf="companyProducts?.length">
            <h2 class="mb-0 mt-3">Программные продукты</h2>
            <div *ngFor="let product of companyProducts">
              {{ product.product_name }}
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Отчёты">
      <div class="row mx-0 mt-4">
        <div class="col">
          <h2 class="mb-1">Отчёт за произвольный период</h2>
          <mat-form-field appearance="legacy">
            <mat-label>Укажите период</mat-label>
            <mat-date-range-input
              [formGroup]="customReportDateRange"
              [rangePicker]="picker"
              [min]="dateRangeMinDate"
              [max]="dateRangeMaxDate"
            >
              <input
                matStartDate
                formControlName="start"
                placeholder="Дата начала"
              />
              <input
                matEndDate
                formControlName="end"
                placeholder="Дата конца"
              />
            </mat-date-range-input>
            <mat-hint>ДД.ММ.ГГГГ-ДД.ММ.ГГГГ </mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <button
            id="btnDonloadReport"
            mat-raised-button
            color="primary"
            class="mx-3"
            [disabled]="!btnGetCustomReport"
            (click)="getReportFile('download', customReportDateRange.value)"
          >
            <mat-icon title="Скачать">download</mat-icon>
          </button>
        </div>
      </div>

      <div class="row mx-0 mt-4">
        <div class="col-12">
          <h2 class="mb-1">Отчёты за предыдущие периоды</h2>
          <table id="report-list" class="w-100">
            <tbody>
              <tr *ngFor="let report of companyReports">
                <td class="d-flex align-items-center">
                  <mat-icon color="accent">calendar_month</mat-icon>
                  <span class="mx-2">{{ report.periodName }}</span>
                  <button
                    mat-icon-button
                    aria-label="Скачать отчёт"
                    title="Скачать отчёт"
                    color="primary"
                    class="ml-auto"
                    [disabled]="!btnGetCustomReport"
                    (click)="
                      getReportFile(
                        'download',
                        null,
                        report.periodBegin,
                        report.periodEnd,
                        $event
                      )
                    "
                  >
                    <mat-icon>file_download</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Акты">
      <div class="row mx-0 my-3">
        <div class="col s12">
          <h2
            *ngIf="!currentContractActs?.length"
            color="accent"
            class="d-flex align-items-center"
          >
            <mat-icon>info</mat-icon>
            <span class="ms-2"
              >Нет актов по договору <b>{{ currentContract?.uuid }}</b> от
              <b>{{ currentContract?.contract_date }}</b></span
            >
          </h2>
          <div class="row">
            <div class="col-12 col-md-10 col-lg-8">
              <table
                id="act-list"
                *ngIf="currentContractActs?.length"
                class="contractActTable"
              >
                <thead>
                  <th>№ Акта</th>
                  <th>Дата</th>
                  <th>Сумма</th>
                </thead>
                <tbody>
                  <tr *ngFor="let act of currentContractActs">
                    <td>{{ act.uuid }}</td>
                    <td>{{ act.date | unixTsToDate }}</td>
                    <td>
                      {{ act.sum | rubles }}
                      <button
                        mat-icon-button
                        aria-label="Скачать акт"
                        title="Скачать акт"
                        color="primary"
                        [disabled]="!btnGetCustomReport"
                        (click)="getActFile(act.uuid, act.date, 'download')"
                      >
                        <mat-icon>file_download</mat-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Скрыть</button>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</mat-dialog-actions>
